export interface ITelemedicineRequestDTO {
  requestId: number;
  practitionerId: number;
  patientFirstName: string;
  patientLastName: string;
  phoneNumber: string;
  email: string;
  status: string;
}

export const initialRequest: ITelemedicineRequestDTO = {
  requestId: 0,
  practitionerId: 0,
  patientFirstName: '',
  patientLastName: '',
  phoneNumber: '',
  email: '',
  status: 'Pending',
};
