import { IWalletTransaction } from './WalletInterface';

export interface IWalletReportSummary {
  incomeThisMonth: number;
  incomeLastMonth: number;
  walletBalance: number;
  weeklyCredit: number;
  weeklyDebit: number;
  topTwoTransactions: Array<IWalletTransaction>;
}

export interface IPatientSummary {
  totalPatients: number;
  patientsThisMonth: number;
  patientsLastMonth: number;
}

export interface IConsultationSummary {
  consultationsThisMonth: number;
  consultationsLastMonth: number;
  consultationMetric: IConsultationType;
}
export interface IConsultationType {
  investigationReports: number;
  prescriptions: number;
  medicalAdvices: number;
}

export const initialConsultationType: IConsultationType = {
  investigationReports: 0,
  medicalAdvices: 0,
  prescriptions: 0,
};

export const initialConsultation: IConsultationSummary = {
  consultationsLastMonth: 0,
  consultationsThisMonth: 0,
  consultationMetric: initialConsultationType,
};

export const initialWalletSummary: IWalletReportSummary = {
  topTwoTransactions: [],
  weeklyDebit: 0,
  walletBalance: 0,
  weeklyCredit: 0,
  incomeLastMonth: 0,
  incomeThisMonth: 0,
};
