import { Link } from 'react-router-dom';

const HeaderLogo = () => {
  return (
    <div className='home-header-area mt-2'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-3'>
            <div className='logo'>
              <Link to='/'>
                <img
                  alt='logo'
                  src={require('../../../assets/images/logo.svg').default}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLogo;
