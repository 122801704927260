import { Modal } from 'react-bootstrap';
import axios from 'axios';
import {
  IWalletAccountDTO,
  IBankList,
  initialBank,
} from '../../../interfaces/WalletInterface';
import { useEffect, useState, useCallback } from 'react';
import { isFormValidated } from '../../../utils/formUtils';
import { endpoints } from '../../../utils/URLs';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../components/common/ButtonLoader';

const WalletModal = ({
  getWalletSummary,
  show,
  close,
  isEdit,
  practitionerId,
}: any) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [bankList, setBankList] = useState<IBankList[]>([]);
  const [showLoading, setShowLoading] = useState(false);

  const initialData: IWalletAccountDTO = {
    practitionerId: 0,
    bankAccountName: '',
    bankName: '',
    bankAccountNumber: '',
    bankCode: '',
  };
  const [walletAccount, setWalletAccount] = useState(initialData);

  const handleChange = (input: string) => (event: any) => {
    setWalletAccount({
      ...walletAccount,
      [input]: event.target.value,
    });
  };

  const bankNameChange = async (event: any) => {
    const selectedCode = event.target.value;
    const selectedBank = bankList.filter((x) => x.code === selectedCode)[0];

    setWalletAccount({
      ...walletAccount,
      bankCode: selectedBank.code,
      bankName: selectedBank.name,
    });

    await validateAccount(
      walletAccount.bankAccountNumber,
      selectedBank.code,
      selectedBank.name,
    );
  };

  const saveWallet = async (event: any) => {
    //verify otp field
    if (!isFormValidated('wallet-modal')) {
      return;
    }

    event.preventDefault();
    if (walletAccount.bankName === '') {
      addToast('Please select a bank name', 'warning');
      return;
    }
    event.preventDefault();

    setButtonLoading(true);

    walletAccount.practitionerId = practitionerId;

    let url = `${endpoints.practitionerWallet.mainUrl}`;

    try {
      if (isEdit) {
        await axios.put(url, walletAccount);
      } else {
        await axios.post(url, walletAccount);
      }

      addToast('Wallet successfully created.', 'success');
      getWalletSummary();
      close();
    } catch (error) {
      addToast('Network error occured.', 'error');
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  const getBankList = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.wallets.bankList);
      response.data.unshift(initialBank);
      setBankList(response.data);
    } catch (error) {
      addToast('Error getting bank list', 'error');
    }
  }, []);

  const validateAccount = async (
    bankAccountNumber: string,
    bankCode: string,
    bankName: string,
  ) => {
    let accountName = '';
    if (bankName === '' || bankCode === '' || bankAccountNumber.length !== 10) {
      addToast('Please input bank account number and/or bank name');

      return accountName;
    }

    const payload = {
      accountNumber: bankAccountNumber,
      accountBank: bankCode,
    };

    setShowLoading(true);
    try {
      const response = await axios.post(
        endpoints.wallets.resolveAccount,
        payload,
      );

      accountName = response.data.accountName;
    } catch (error) {
      accountName = '';
      addToast('The Account name could not be loaded', 'error');
    } finally {
      setShowLoading(false);
    }
    setWalletAccount({
      ...walletAccount,
      bankAccountName: accountName,
      bankCode: bankCode,
      bankName: bankName,
    });
    return accountName;
  };

  useEffect(() => {
    getBankList();
  }, [getBankList]);
  return (
    <Modal
      show={show}
      onHide={close}
      centered
      backdrop={showLoading ? 'static' : true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Update Wallet' : 'Create Wallet'}</Modal.Title>
      </Modal.Header>
      <form id='wallet-modal'>
        <Modal.Body>
          <fieldset disabled={buttonLoading}>
            <div className='col-md-12'>
              <div className='form-group mt-3'>
                <input
                  type='text'
                  className='form-control'
                  value={walletAccount.bankAccountNumber}
                  onChange={handleChange('bankAccountNumber')}
                  required
                  minLength={10}
                  maxLength={10}
                  pattern='[0-9]{10}'
                  placeholder='Bank Account Number'
                />
              </div>
              <div className='form-group mt-3' style={{ marginBottom: '30px' }}>
                <small>Bank Name</small>
                <select
                  className='form-select'
                  value={walletAccount.bankCode}
                  onChange={bankNameChange}
                >
                  {bankList.map((bank) => (
                    <option key={bank.id} value={bank.code}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group mt-3'>
                {showLoading ? (
                  <div>
                    <span style={{ color: 'gray', fontSize: '0.8rem' }}>
                      Fetching account name.....
                    </span>
                    <div
                      style={{
                        width: '0.8rem',
                        height: '0.8rem',
                        float: 'right',
                        marginTop: '10px',
                      }}
                      className='spinner-border spinner-border-lg'
                      role='status'
                      aria-hidden='true'
                    ></div>
                  </div>
                ) : (
                  ''
                )}
                <input
                  type='text'
                  className='form-control'
                  value={walletAccount.bankAccountName}
                  //onChange={handleChange('bankAccountName')}
                  required
                  placeholder='Bank Account Name'
                />
              </div>

              <div className='form-group mt-3'>
                <button
                  type='submit'
                  className='btn btn-block btn-success'
                  onClick={saveWallet}
                  style={{ backgroundColor: '#191489' }}
                  disabled={
                    walletAccount.bankAccountName === '' ||
                    walletAccount.bankAccountNumber === '' ||
                    walletAccount.bankCode === '' ||
                    showLoading
                  }
                >
                  {isEdit ? 'Update wallet' : 'Create my wallet'}
                  {buttonLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            </div>
          </fieldset>
        </Modal.Body>
      </form>
    </Modal>
  );
};

export default WalletModal;
