import { IPatientData } from '../../../interfaces/PatientInterface';

const PatientInfo = ({
  patientData,
  viewFunction,
  navigationFunction,
}: {
  patientData: IPatientData;
  viewFunction?: Function;
  navigationFunction?: Function;
}) => {
  const viewPrescriptions = () => {
    if (viewFunction) {
      viewFunction();
    }
  };

  const navigateToRoute = (route: string) => (event: any) => {
    event.preventDefault();

    if (navigationFunction) {
      navigationFunction(route);
    }
  };

  return (
    <div className='mt-4 p-3 prescription-summary border-primary'>
      <div className='d-flex mt-1'>
        <div className='fw-600'>
          Name:
          <span className='fw-400 ms-1'>
            {patientData.firstName} {patientData.lastName}
          </span>
        </div>
        <div className='ms-4 fw-600'>
          Gender:
          <span className='fw-400 ms-1'>{patientData.gender}</span>
        </div>
        <div className='ms-4 fw-600'>
          Age:
          <span className='fw-400 ms-1'>
            {patientData.dateOfBirth !== null
              ? new Date().getFullYear() -
                new Date(patientData.dateOfBirth!).getFullYear()
              : 'NIL'}
          </span>
        </div>
      </div>
      <div className='d-flex gap-2 mt-2'>
        <div className='fw-600'>
          Phone:
          <span className='fw-400 ms-1'>{patientData.phoneNumber}</span>
        </div>
      </div>
      <div className='d-flex gap-2 mt-2'>
        <div className='fw-600'>
          Email:
          <span className='fw-400 ms-1'>{patientData.email}</span>
        </div>
      </div>
      <div className='d-flex gap-2 mt-2'>
        <div className='fw-600'>
          Address:
          <span className='fw-400 ms-1'>{patientData.location}</span>
        </div>
      </div>

      <div className='mt-3 row justify-content-center'>
        <div className='col-lg-3 col-md-4 col-sm-6 mt-3 d-grid'>
          <button
            className='quick-tile-card'
            type='button'
            onClick={viewPrescriptions}
          >
            <img
              src={
                require(`../../../assets/images/prescription-pad-transparent.svg`)
                  .default
              }
              className='img-fluid'
              alt='View Presciriptions'
            />
            <div className='paragraph-text m-0 align-items-middle fs-16'>
              View Prescriptions
            </div>
          </button>
        </div>
        <div className='col-lg-3 col-md-4 col-sm-6 mt-3 d-grid'>
          <button
            className='quick-tile-card'
            onClick={navigateToRoute('/prescriptions/new')}
          >
            <img
              src={
                require(`../../../assets/images/prescription-pad-transparent.svg`)
                  .default
              }
              className='img-fluid'
              alt='View Presciriptions'
            />
            <div className='paragraph-text m-0 align-items-middle fs-16'>
              Write Prescription
            </div>
          </button>
        </div>
        <div className='col-lg-3 col-md-4 col-sm-6 mt-3 d-grid'>
          <button
            className='quick-tile-card'
            onClick={navigateToRoute('/prescriptions/medical-advice')}
          >
            <img
              src={
                require(`../../../assets/images/prescription-pad-transparent.svg`)
                  .default
              }
              className='img-fluid'
              alt='View Presciriptions'
            />
            <div className='paragraph-text m-0 align-items-middle fs-16'>
              Write Medical Advice
            </div>
          </button>
        </div>
        <div className='col-lg-3 col-md-4 col-sm-6 mt-3  d-grid'>
          <button
            className='quick-tile-card'
            onClick={navigateToRoute('/prescriptions/investigation-report')}
          >
            <img
              src={
                require(`../../../assets/images/prescription-pad-transparent.svg`)
                  .default
              }
              className='img-fluid'
              alt='View Presciriptions'
            />
            <div className='paragraph-text m-0 align-items-middle fs-16'>
              Write Report
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
