import { Modal } from 'react-bootstrap';
import { IConfirmationModal } from '../../../interfaces/AppInterfaces';

const ConfirmationModal = ({
  show,
  hide,
  title,
  content,
  okText,
  cancelText,
  okCallback,
  cancelCallback,
}: IConfirmationModal) => {
  const okBtnClick = () => {
    if (okCallback) {
      okCallback();
    } else {
      hide();
    }
  };
  const cancelBtnClick = () => {
    if (cancelCallback) {
      cancelCallback();
    }
  };

  return (
    <div>
      <Modal show={show} centered backdrop='static'>
        <Modal.Header>
          <Modal.Title style={{ color: '#1489C8' }}>{title}</Modal.Title>
        </Modal.Header>
        <div>
          <Modal.Body>{content}</Modal.Body>

          <Modal.Footer>
            <button className='btn btn-primary' onClick={okBtnClick}>
              {okText ? okText : 'Ok'}
            </button>
            {cancelCallback ? (
              <button className='btn btn-warning' onClick={cancelBtnClick}>
                {cancelText ? cancelText : 'Cancel'}
              </button>
            ) : (
              ''
            )}
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
