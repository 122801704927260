import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useOutsideAlerter } from './OutsideClick';
import {
  IPatientData,
  IPatientSearchBar,
} from '../../../interfaces/PatientInterface';
import { endpoints } from '../../../utils/URLs';

const PatientSearch = ({ callback, inputStyle }: IPatientSearchBar) => {
  const [patientSearchResult, setPatientSearchResult] = useState<
    IPatientData[]
  >([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  let timer: NodeJS.Timeout;
  const axiosInstance = axios.create();

  const hideSearchResult = (event: any) => {
    setShowSearch(false);
  };

  const inputFocus = () => {
    setShowSearch(true);
  };

  /*To handle when we clck outside the search */

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, hideSearchResult);

  /* end click outside */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputSearch = event.target.value;
    setSearchTerm(inputSearch);
    // if (inputSearch.length < 3) {
    //   return;
    // }
    // searchPatient(inputSearch);
  };
  const clearTimer = () => {
    clearTimeout(timer);
  };

  const startTimer = (event: any) => {
    clearTimeout(timer);
    let searchTerm = event.target.value;
    timer = setTimeout(() => searchPatient(searchTerm), 1000);
  };

  const searchPatient = async (searchTerm: string) => {
    if (searchTerm.length < 3) {
      return;
    }

    //show loading
    const loading: IPatientData = {
      patientId: 0,
      firstName: 'Loading...',
      lastName: '',
      phoneNumber: '',
      email: '',
      location: '',
      dateOfBirth: '',
      gender: 'Others',
    };
    const loadingArray = [loading];
    setPatientSearchResult(loadingArray);
    setShowSearch(true);

    const url = `${endpoints.patients.mainUrl}?searchQuery=${searchTerm}`;

    try {
      const response = await axiosInstance.get(url); //use a new instance of axios to avoid showing full screen loading
      setPatientSearchResult(response.data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const selectPatient = (selectedPatient: IPatientData) => {
    setSearchTerm(selectedPatient.phoneNumber);
    setShowSearch(false);

    //callback set, call the callback passing the selected patient data
    if (callback) {
      callback(selectedPatient);
    }
  };

  return (
    <div className='form-group ' ref={wrapperRef} onFocus={inputFocus}>
      {/* onFocus={toggleSearchResult} onBlur={toggleSearchResult}> */}
      <span className='bi bi-search search-icon'></span>
      <input
        type='text'
        className={
          inputStyle
            ? 'form-control ps-5 prescription-search' + inputStyle
            : 'form-control ps-5 prescription-search'
        }
        placeholder='Search. Start typing phone number to select'
        onChange={handleChange}
        value={searchTerm}
        onKeyUp={startTimer}
        onKeyDown={clearTimer}
      />
      {showSearch && patientSearchResult.length > 0 ? (
        <div className='search-result-panel'>
          <ul style={{ marginLeft: '-30px', backgroundColor: 'white' }}>
            {patientSearchResult.map((patient) => (
              <li
                key={patient.patientId}
                className='search-result'
                onClick={() => selectPatient(patient)}
              >
                {patient.firstName} {patient.lastName} - {patient.phoneNumber}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PatientSearch;
