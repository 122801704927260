import { Dispatch, IAppState } from '../interfaces/AppInterfaces';

const appReducer = (state: IAppState, action: Dispatch): IAppState => {
  switch (action.type) {
    case 'LOG_OUT':
      sessionStorage.clear();
      localStorage.clear();
      return {
        token: '',
        isAuthenticated: false,
        practitioner: undefined,
        prescriptionResponse: undefined,
        wallet: undefined,
        role: '',
        telemedicineRequest: undefined,
      };
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload,
        isAuthenticated: true,
        role: 'User',
      };
    case 'UPDATE_PROFILE':
      return {
        ...state,
        practitioner: action.payload,
      };
    case 'SET_PRESCRIPTION_RESPONSE':
      return {
        ...state,
        prescriptionResponse: action.payload,
      };
    case 'SET_WALLET':
      return {
        ...state,
        wallet: action.payload,
      };
    case 'SET_TELEMEDICINE_REQUEST':
      return {
        ...state,
        telemedicineRequest: action.payload,
      };

    case 'SET_REGISTRATION_DATA':
      return {
        ...state,
        registrationData: action.payload,
      };
    case 'SET_PRESCRIPTION_DATA':
      return {
        ...state,
        prescriptionData: action.payload,
      };
    case 'SET_PRESCRIPTION_NOTE':
      return {
        ...state,
        prescriptionNote: action.payload,
      };
    case 'SET_PATIENT_DATA':
      return {
        ...state,
        patientData: action.payload,
      };
    case 'SET_PROPERTY_STATE': //updates a property of ANY IAppState object e.g. firstName. to use, pass the property name and new value e.g propName:'subscriptionCode', propData:'9720KKW'
      return {
        ...state,
        [action.propName]: action.propData,
      };
    default:
      return state;
  }
};

export default appReducer;
