import { Badge } from 'react-bootstrap';
import AddToHomeScreen from '../common/AddToHomeScreen';
import { useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import { AppContext } from '../../../context/AppContext';
import { Link } from 'react-router-dom';

const Header = ({
  notificationEnabled,
  notificationCount,
  toggleNotification,
}: {
  notificationEnabled: boolean;
  notificationCount: number;
  toggleNotification?: Function;
}) => {
  const { dispatch } = useContext(AppContext);
  const auth = useAuth();

  const toggle = () => {
    if (toggleNotification) {
      toggleNotification();
    }
  };

  const logOut = (event: any) => {
    dispatch({
      type: 'LOG_OUT',
    });
    auth
      .signoutRedirect()
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });

    //window.location.href = "/";

    // dispatch({
    //   type: "LOG_OUT",
    // });
  };

  return (
    <nav
      className='navbar navbar-light sticky-top flex-md-nowrap p-0'
      style={{ height: 'auto', backgroundColor: '#ffffff' }}
    >
      <a
        className='col-md-3 col-lg-2 mr-0 px-3'
        style={{ margin: '10px 0px' }}
        href='/'
      >
        <img
          src={require('../../../assets/images/logo.svg').default}
          alt='wellahealth'
          width='64'
        />
      </a>
      <button
        className='navbar-toggler position-absolute d-md-none collapsed'
        type='button'
        data-toggle='collapse'
        data-target='#sidebarMenu'
        aria-controls='sidebarMenu'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        {/* <span
          className='navbar-toggler-icon'
          data-toggle='collapse'
          data-target='#sidebarMenu'
        > */}
        <img
          src={require('../../../assets/images/menu.svg').default}
          alt='Nav Menu'
          width='26px'
          height='26px'
          data-toggle='collapse'
          data-target='#sidebarMenu'
        />
        {/* </span> */}
      </button>

      <ul className='navbar-nav mt-5 mt-md-0 me-1 me-sm-5'>
        <li className='nav-item dropdown text-nowrap text-right'>
          <span className=' d-none d-sm-inline-block'>
            <AddToHomeScreen />
          </span>

          <Link
            to='/'
            className='ms-4 outline-primary-button  d-none d-sm-inline-block'
            onClick={logOut}
          >
            Sign out
          </Link>
          <button
            id='installBtn'
            className='btn ms-5'
            style={{ marginTop: '-5px' }}
            onClick={toggle}
          >
            <span
              className={
                notificationEnabled ? 'bi bi-bell-fill' : 'bi bi-bell-slash'
              }
              title={
                notificationEnabled
                  ? 'Turn notification off'
                  : 'Turn notification on'
              }
            >
              {notificationEnabled ? (
                <Badge style={{ color: '#007bff' }}>
                  {notificationCount < 99 ? notificationCount : '99+'}
                </Badge>
              ) : (
                ''
              )}
            </span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
