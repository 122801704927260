const TableLoader = () => {
  return (
    <tbody>
      <tr className='text-center'>
        <td colSpan={100}>
          <span
            className='spinner-border spinner-border-lg table-spinner'
            role='status'
            aria-hidden='true'
          ></span>
        </td>
      </tr>
    </tbody>
  );
};

export default TableLoader;
