import { IRegistrationData } from '../../../interfaces/AuthInterface';
import { IAppState, IProps } from '../../../interfaces/AppInterfaces';
import { useState, useContext, useEffect } from 'react';
import { isFormValidated } from '../../../utils/formUtils';
import { AppContext } from '../../../context/AppContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { endpoints } from '../../../utils/URLs';

const RegisterForm = (props: IProps): JSX.Element => {
  const [isRegistered, setIsRegistered] = useState(false);

  const initialData: IRegistrationData = {
    phoneNumber: '',
    email: '',
    mdcnfolioNumber: '',
    lastName: '',
    firstName: '',
    password: '',
    confirmPassword: '',
    location: 'Nigeria',
    serviceCharge: 0,
  };
  const [registrationData, setRegistrationData] = useState(initialData);

  const { appState, dispatch }: { appState: IAppState; dispatch: any } =
    useContext(AppContext);

  const handleChange = (input: string) => async (event: any) => {
    const value = event.target.value;
    setRegistrationData({
      ...registrationData,
      [input]: value,
    });

    //check if user is already registered silently
    if (input === 'phoneNumber' && value.length > 10) {
      getPractitioner(event.target.value);
    }
  };

  const register = async (event: any) => {
    if (!isFormValidated('register-form')) {
      return;
    }

    event.preventDefault();

    dispatch({
      type: 'SET_REGISTRATION_DATA',
      payload: registrationData,
    });

    props.history.push('/register/licence');
  };

  const getPractitioner = async (phoneNumber: string) => {
    if (phoneNumber.startsWith('+')) {
      phoneNumber = phoneNumber.substring(1);
    }

    const url = `${endpoints.practitioner.mainUrl}/${phoneNumber}`;
    setIsRegistered(true);

    const axiosInstance = axios.create(); //using an instance to bypass generic error

    try {
      await axiosInstance.get(url);
    } catch (error) {
      setIsRegistered(false);
      console.error(error);
    }
  };

  useEffect(() => {
    //check if we have registration data
    if (appState.registrationData && appState.registrationData !== null) {
      setRegistrationData(appState.registrationData);
    }
  }, [props.history, appState]);

  return (
    <section className='container register-section'>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='page-card p-5'>
            <div className='logo'>
              <Link to='/'>
                <img
                  alt='logo'
                  src={require('../../../assets/images/logo.svg').default}
                />
              </Link>
            </div>
            <div className='page-card-header fs-56 fw-700'>
              Make money giving medical advice...
            </div>
            <div className='page-card-subtitle fs-18 fw-400 mt-3'>
              Join a growing community of freelance healthcare providers, who
              are maximizing their earnings using our remote consultation
              management tools.
            </div>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='row justify-content-center'>
            <div className='col-md-8' style={{ padding: '90px 30px' }}>
              <h1 className='page-title fs-56 fw-700'>Sign up for free</h1>
              <div className='page-subtitle'>Lets get you started</div>
              <form
                id='register-form'
                className='row justify-content-center mt-5'
              >
                <fieldset>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <label>First Name</label>
                      <span className='text-danger'>*</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='First name'
                        value={registrationData.firstName}
                        onChange={handleChange('firstName')}
                        required
                        style={{ marginBottom: '30px' }}
                      />
                    </div>
                    <div className='form-group'>
                      <label>Last Name</label>
                      <span className='text-danger'>*</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Last name'
                        value={registrationData.lastName}
                        onChange={handleChange('lastName')}
                        required
                        style={{ marginBottom: '30px' }}
                      />
                    </div>
                    <div className='form-group'>
                      <label>Phone Number</label>
                      <span className='text-danger'>*</span>
                      <input
                        type='text'
                        className='form-control'
                        aria-describedby='phoneHelp'
                        value={registrationData.phoneNumber}
                        onChange={handleChange('phoneNumber')}
                        maxLength={13}
                        required
                        pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                        placeholder='Phone Number'
                      />
                      <small id='phoneHelp' className='form-text text-muted'>
                        We'll never share your phone number with anyone else.
                      </small>
                    </div>
                    <div className='form-group mt-3'>
                      <label>Email</label>
                      <span className='text-danger'>*</span>
                      <input
                        type='email'
                        className='form-control'
                        aria-describedby='emailHelp'
                        value={registrationData.email}
                        onChange={handleChange('email')}
                        required
                        placeholder='Enter Email'
                      />
                      <small id='emailHelp' className='form-text text-muted'>
                        We'll never share your email with anyone else.
                      </small>
                    </div>
                    <div className='form-group mt-3'>
                      <label>Password</label>
                      <span className='text-danger'>*</span>
                      <input
                        type='password'
                        className='form-control'
                        value={registrationData.password}
                        onChange={handleChange('password')}
                        required
                        placeholder='Enter Password'
                        pattern='^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,24}$'
                        title='Password must be atleast 6 characters, with atleast one number and one letter'
                      />
                      <em style={{ fontSize: '0.8rem', lineHeight: '0px' }}>
                        Aleast 6 characters, with atleast one number, one letter
                      </em>
                    </div>

                    <div className='form-group mt-3'>
                      <label>Confirm Password</label>
                      <span className='text-danger'>*</span>
                      <input
                        type='password'
                        className='form-control'
                        value={registrationData.confirmPassword}
                        onChange={handleChange('confirmPassword')}
                        required
                        placeholder='Confirm password'
                        pattern='^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,24}$'
                        title='Password must be atleast 6 characters, with atleast one number and one letter'
                        style={{ marginBottom: '30px' }}
                      />
                    </div>
                    {isRegistered ? (
                      <div className='text-danger'>
                        Looks like you already have an account. Please
                        <Link to='/' className='ms-1'>
                          Log in
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='form-group mt-3'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-block btn-primary'
                        onClick={register}
                        disabled={isRegistered}
                      >
                        Continue
                      </button>
                    </div>
                    <div className='mt-3'>
                      Already have an account?
                      <Link to='/' className='ms-2'>
                        Log in
                      </Link>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterForm;
