import { Link } from 'react-router-dom';
import { Accordion, Carousel } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import './home.css';
import HomeHeader from './components/common/HomeHeader';

const Home = () => {
  const showCarousel = useCallback(() => {
    let items = document.querySelectorAll('.carousel .carousel-item');
    items.forEach((el) => {
      // number of slides per carousel-item

      const minPerSlide = 3;
      let next = el.nextElementSibling;
      for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0];
        }
        let cloneChild = next.cloneNode(true) as any;
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });
  }, []);

  useEffect(() => {
    showCarousel();
  }, [showCarousel]);

  return (
    <div className=''>
      <HomeHeader />
      <section className='container mt-sm-5 pt-sm-5'>
        <div className='row'>
          {/* <section className='hero-section'> */}
          {/* <div className='col-md-6 px-3 d-flex flex-column justify-content-center align-items-start'> */}
          <div className='col-lg-6 mt-5 position-relative'>
            <div className='section-header mb-3 fs-60 text-center text-sm-start'>
              Unlock the value of
              <br className='d-none d-md-block' /> your medical expertise
            </div>
            <div className='paragraph-text mt-4 mt-sm-3 fs-20 text-center text-sm-start'>
              Turn casual medical advice, prescriptions, and investigation
              report analyses into a
              <br className='d-block d-sm-none' />
              <strong className='ms-1'>steady income stream.</strong>
            </div>
            <div className='hero-buttons d-md-block mt-5 row'>
              <Link
                to='/register'
                className='primary-button mt-3 ms-sm-3 fs-20 text-center text-sm-start'
              >
                Sign Up For Free
              </Link>
              <Link
                to='/'
                className='ms-sm-3 mt-3 outline-primary-button fs-20  text-center text-sm-start'
              >
                Learn More
              </Link>
            </div>
            <div className='small-doctor pt-4 d-none d-lg-block'>
              <img
                src={require('../assets/images/small-doctor.svg').default}
                className='img-fluid'
                alt='Doctor'
              />
              <p className='paragraph-text m-0 fs-20'>
                <strong>170+</strong> Nigerian doctors
                <br /> are making money on ERx
              </p>
            </div>
          </div>
          {/* <div className='col-md-6 d-flex flex-column gap-3 justify-content-center align-items-center my-5'> */}
          <div className='col-lg-6 text-center text-lg-end mt-5'>
            <img
              src={require('../assets/images/hero-image.svg').default}
              className='img-fluid'
              alt=''
            />
            <div className='small-doctor-2 d-flex mt-4 justify-content-center d-block d-lg-none'>
              <img
                src={require('../assets/images/small-doctor.svg').default}
                width='52px'
                height='52px'
                alt='Doctor'
              />
              <span className='ms-3 paragraph-text m-0 text-start fs-20'>
                <strong>170+</strong> Nigerian doctors
                <br /> are making money on ERx
              </span>
            </div>
          </div>
          {/* </section> */}
        </div>
      </section>
      <section className='container ps-4 pe-4'>
        <div className='row mt-5 about-container'>
          <div className='col-lg-6  position-relative'>
            <h1 className='section-subheader-2 mt-5 fs-48'>
              Freelancing doesn't <br className='d-none d-sm-block' /> mean
              working for
              <span className='ms-2 text-gold'>free.</span>
            </h1>
            <p className='paragraph-text text-white mt-4 pe-sm-5 me-sm-5 fs-20'>
              Most doctors give professional advice for free... Yet, thousands
              of doctors are <strong>earning</strong> extra income on their
              medical advice.
            </p>
            <Link
              to='/register'
              className='mt-5 btn outline-primary-button d-none  d-lg-inline-block'
            >
              Become A Provider
            </Link>
          </div>
          <div className='col-lg-3 mt-3 position-relative'>
            <div className='about-card'>
              <p className='paragraph-text text-center fs-20'>
                I struggle with asking people to pay for my medical advice. If I
                had a dollar for every time I gave a free consult, I wouldn't
                always be broke.
              </p>
              <div className='mt-sm-5 text-center  doctor-image-section'>
                <img
                  className='img-fluid'
                  src={require('../assets/images/about-card1.svg').default}
                  alt='person'
                />
              </div>
            </div>
          </div>
          <div className='col-lg-3 mt-3 position-relative'>
            <div className='about-card'>
              <p className='paragraph-text text-center fs-20'>
                You'd be surprised, but people are totally cool with paying. I
                mean, I make some extra cash just by helping people understand
                their lab results
              </p>
              <div className='mt-sm-5 text-center doctor-image-section'>
                <img
                  className='img-fluid'
                  src={require('../assets/images/about-card2.svg').default}
                  alt='person'
                />
              </div>
            </div>
          </div>
          <div className='text-center mt-4'>
            <Link
              to='/register'
              className='outline-primary-button d-block  d-lg-none'
            >
              Become A Provider
            </Link>
          </div>
        </div>
      </section>
      <section className='container text-center section-margin'>
        <div className='section2-header text-capitalize text-center fs-48 pe-3 ps-3 pe-sm-0 ps-sm-0'>
          turn casual medical advice, prescriptions,
          <br /> and investigation report analyses
          <br /> into a legitimate source of income.
        </div>
        <div className='mt-5'>
          <Link
            to='/register'
            className='primary-button mx-auto d-sm-inline-block d-block'
          >
            Sign Up For Free
          </Link>
        </div>
      </section>
      <section className='container mt-5' id='about'>
        <div className='row'>
          <div className='col-md-6'>
            <div
              className='card display-card-1'
              style={{ backgroundColor: '#E0F5FE' }}
            >
              <h3 className='section3-subheader pe-sm-5 fs-30'>
                Generate sharable links to your professional profile.
              </h3>
              <p className='paragraph-text mt-0 mt-sm-3 fs-20'>
                Your very own <strong>professional</strong> page, where your
                customers can view your qualifications and services you offer.
              </p>
              <div className='mt-4'>
                <img
                  src={require('../assets/images/card1-image.svg').default}
                  className='img-fluid'
                  alt='shareable link'
                />
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className='card display-card-1'
              style={{ backgroundColor: '#FFECEA' }}
            >
              <h3 className='section3-subheader pe-sm-5 fs-30'>
                Paywall interactions with your customers.
              </h3>
              <p className='paragraph-text mt-0 mt-sm-3 fs-20'>
                You can have your <strong>paywalled</strong> consultations on
                any platform of your convenience.
              </p>
              <div className='mt-4'>
                <img
                  src={require('../assets/images/card2-image.svg').default}
                  className='img-fluid'
                  alt='alert'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container mt-sm-5' id='how-it-works'>
        <div className='row justify-content-center'>
          <div className='col-md-6 col-lg-4'>
            <div
              className='card display-card-2'
              style={{ backgroundColor: '#FFFAEA' }}
            >
              <h3 className='section3-subheader pe-sm-5 fs-30'>
                Customized letter headed prescriptions.
              </h3>
              <p className='paragraph-text mt-0 mt-sm-3 fs-20'>
                Your prescriptions should look <strong>professional</strong>, so
                they're not rejected at the pharmacy.
              </p>
              <div className='mt-4'>
                <img
                  src={require('../assets/images/prescription.svg').default}
                  className='img-fluid'
                  alt='prescription'
                  loading='lazy'
                />
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-4'>
            <div
              className='card display-card-2'
              style={{ backgroundColor: '#E9E8FF' }}
            >
              <h1 className='section3-subheader pe-sm-5 fs-30'>
                Collaborate with colleagues.
              </h1>
              <p className='paragraph-text  mt-0 mt-sm-3 fs-20'>
                Sometimes, providing quality healthcare means
                <strong className='ms-2'>collaborating</strong> with your peers.
              </p>
              <div className='mt-4'>
                <img
                  src={require('../assets/images/doctor-community.svg').default}
                  className='img-fluid'
                  alt='collaborate'
                  loading='lazy'
                />
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-4'>
            <div
              className='card display-card-2'
              style={{ backgroundColor: '#F2F2F2' }}
            >
              <h3 className='section3-subheader pe-sm-3 fs-30'>
                Access our vast network of vetted labs and pharmacy
              </h3>
              <p className='paragraph-text  mt-0 mt-sm-3 fs-20'>
                Provide holistic care to your patients with Wellahealth's
                providers network
              </p>
              <div className='mt-4'>
                <img
                  src={require('../assets/images/hospital.svg').default}
                  loading='lazy'
                  className='img-fluid'
                  alt='pharmacy'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container text-center section-margin'>
        <div className='section2-header text-capitalize text-center fs-48'>
          Start earning in no time
        </div>
        <p className='paragraph-text text-center fs-20 pe-2 pe-sm-0 ps-2 ps-sm-0'>
          Unlock the Power of Your Expertise in Three Simple Steps
        </p>
        <div className='mt-5'>
          <Link
            to='/register'
            className='primary-button mx-auto d-sm-inline d-block'
          >
            Sign Up For Free
          </Link>
        </div>
      </section>

      {/* Earning features */}
      <section className='container mt-5'>
        <div className='row'>
          <div className='col-lg-4 text-center text-sm-start mt-5'>
            {/* <div className='d-flex flex-column align-items-start gap-4'> */}
            <div>
              <img
                className='earning-icon'
                src={require('../assets/images/person-plus.svg').default}
                alt='person'
              />
            </div>
            <div className='section5-subheader fs-24 mt-3'>
              Create your ERx Account
            </div>
            <p className='section5-text  mt-3 fs-20'>
              To begin your journey, all you need is to sign up with your
              professional credentials. Our secure and user-friendly interface
              ensures a quick and hassle-free registration process.
            </p>
          </div>
          <div className='col-lg-4 text-center text-sm-start mt-5'>
            <div>
              <img
                className='earning-icon'
                src={require('../assets/images/link.svg').default}
                alt='link'
              />
            </div>
            <div className='section5-subheader fs-24 mt-3'>
              Generate your unique link
            </div>

            <p className='section5-text  mt-3 fs-20'>
              Once registered, you'll receive a unique link. This link acts as
              your personalized gateway to monetize your medical services. Share
              it with friends, family, or anyone who seeks your professional
              advice.
            </p>
          </div>
          <div className='col-lg-4 text-center text-sm-start mt-5'>
            <div>
              <img
                className='earning-icon'
                src={require('../assets/images/coins.svg').default}
                alt='coin'
              />
            </div>
            <div className='section5-subheader mt-3 fs-24'>
              Start receiving payment
            </div>

            <p className='section5-text  mt-3 fs-20'>
              When someone uses your link to access your services, you get paid.
              It's as simple as that. No more awkward conversations about money;
              just seamless, ethical monetization.
            </p>
          </div>
        </div>
      </section>

      <section className='bg-home-2 section-margin'>
        <section className='p-sm-5 p-3 carousel-section'>
          <h1 className='section5-header text-sm-start text-center ps-1 ps-sm-2 fs-48'>
            Don't just take our words for it.
          </h1>
          <p className='paragraph-text text-sm-start text-center ps-1 ps-sm-2 fs-20'>
            Hear from other doctors using the platform
          </p>
          <Carousel
            slide={true}
            controls={false}
            interval={1000}
            // id='carouselExampleSlidesOnly'
            className='carousel slide  mt-5'
            // data-bs-ride='carousel'
          >
            <div className='carousel-inner'>
              <Carousel.Item className='carousel-item active'>
                <div className='col-md-4 p-3'>
                  <div className='carousel-card p-4'>
                    <h3 className='carousel-card-text1 fs-20'>
                      “Fits right into my daily grind. From writing a
                      prescription to seeing that extra cash, it's smooth
                      sailing. But hey, nothing's perfect - a few tweaks here
                      and there would be great.”
                    </h3>
                    <h3 className='carousel-card-text2 fs-20'>Dr. Okuneye</h3>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item className='carousel-item'>
                <div className='col-lg-4 p-3'>
                  <div className='carousel-card p-4'>
                    <h3 className='carousel-card-text1 fs-20'>
                      “The user-friendly interface has made charging for online
                      prescriptions a walk in the park. Highly recommend it!”
                    </h3>
                    <h3 className='carousel-card-text2 fs-20'>Dr. Opara</h3>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item className='carousel-item'>
                <div className='col-lg-4 p-3'>
                  <div className='carousel-card p-4'>
                    <h3 className='carousel-card-text1 fs-20'>
                      Big shoutout to Erx.ng! They've tackled that pesky issue I
                      had with billing and collecting payments from my clients.
                      Such a relief!
                    </h3>
                    <h3 className='carousel-card-text2 fs-20'>Dr. Dabere</h3>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item className='carousel-item'>
                <div className='col-lg-4 p-3'>
                  <div className='carousel-card p-4'>
                    <h3 className='carousel-card-text1 fs-20'>
                      I can't think of a more innovative and necessary solution
                      for Nigerian doctors at the moment. Doctors should not be
                      shy to monetize their valuable knowledge and skills
                      regardless of who the recipients are.
                    </h3>
                    <h3 className='carousel-card-text2 fs-20'>
                      Dr. Adedayo Sobamowo MD, MBA.
                    </h3>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item className='carousel-item'>
                <div className='col-lg-4 p-3'>
                  <div className='carousel-card p-4'>
                    <h3 className='carousel-card-text1 fs-20'>
                      This is an incredible initiative, with a huge opportunity
                      to fix issues in the pathways to care while addressing
                      stakeholder alignment. Kudos to the Wellahealth team!!
                    </h3>
                    <h3 className='carousel-card-text2 fs-20'>Jeff Stine</h3>
                  </div>
                </div>
              </Carousel.Item>
            </div>
          </Carousel>
        </section>

        <section className='section-margin d-flex flex-column align-items-center gap-3'>
          <h1 className='section-subheader text-center fs-48'>
            Frequently Asked Questions
          </h1>
          <p className='paragraph-text text-center fs-20'>
            Some of our providers frequently asked questions. See if our answers
            to them
            <br /> address your own questions and concerns.
          </p>

          <Accordion defaultActiveKey='0' flush>
            <Accordion.Item eventKey='0'>
              <Accordion.Header className='accordion-header-text fs-24'>
                How do I sign up as a healthcare provider on ERx?
              </Accordion.Header>
              <Accordion.Body className='accordion-body accordion-content'>
                Click on the “Sign up for free” button, and simply follow the
                instructions. You will be required to provide your Medical and
                Dental Council of Nigeria (MDCN) registration and folio numbers,
                so be sure to have them ready.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1' className='mt-2 mt-sm-4'>
              <Accordion.Header className='accordion-header-text fs-24'>
                What are the eligibility criteria for being a provider on ERx
              </Accordion.Header>
              <Accordion.Body className='accordion-body accordion-content'>
                The only criterion to become a provider on ERx, is that you must
                be licensed by the Medical and Dental Council of Nigeria (MDCN)
                to practice.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2' className='mt-2 mt-sm-4'>
              <Accordion.Header className='accordion-header-text fs-24'>
                How much do I get paid to consult on ERx?
              </Accordion.Header>
              <Accordion.Body className='accordion-body accordion-content'>
                You, as a provider, determine your consultation rates on ERx.
                You may also be entitled to commissions if your patients use
                services within the Wellahealth ecosystem. to practice.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='3' className='mt-2 mt-sm-4'>
              <Accordion.Header className='accordion-header-text fs-24'>
                How much do I have to pay to access this service?
              </Accordion.Header>
              <Accordion.Body className='accordion-body accordion-content'>
                The ERx service is completely free for providers.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <h1 className='section-subheader text-center mt-5 fs-48 ps-4 pe-4 pe-sm-0 ps-sm-0'>
            Do You Have More Questions?
          </h1>
        </section>
        <div className='text-center mt-5 ps-4 pe-4 ps-sm-0 pe-sm-0'>
          <Link to='/' className='primary-button d-block d-sm-inline-block'>
            Contact Our Customer Care
          </Link>
        </div>
        <footer className='bg-home-2 container pt-5 mt-5 pb-4'>
          <div className='row'>
            <div className='col-md-4 mt-2'>
              <div className='text-sm-start text-center'>
                <img
                  src={require('../assets/images/logo.svg').default}
                  className='img-fluid'
                  alt='logo'
                />
              </div>
            </div>
            <div className='col-md-4 mt-2'>
              <div className='text-center'>
                Made with ❤️ by doctors and techies at Wellahealth
              </div>
            </div>
            <div className='col-md-4 mt-2'>
              <div className='text-sm-end text-center'>
                <Link to='/' className='btn'>
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
};

export default Home;
