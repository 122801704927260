import { ILeaderboard } from '../../../../interfaces/CommonInterface';
import NameBadge from '../../common/NameBadge';

const TopDoctor = (props: ILeaderboard) => {
  return (
    <div className='d-flex analytics-card1-inner align-items-start mt-3'>
      <div className='d-flex align-items-center'>
        {/* TODO: Show profile image or name badge */}
        {/* <img
          src={require(`../../../assets/images/small-doctor.svg`).default}
          className='img-fluid'
          alt='doctor'
        /> */}
        <NameBadge
          firstName={props.doctorFirstName}
          lastName={props.doctorLastName}
        />
        <div className='ms-3'>
          <div className='grey-700 f-16 fw-600'>
            {props.doctorFirstName} {props.doctorLastName}
          </div>
          <div className='grey-600 fs-12 fw-400'>
            {props.consultations} Consultations
          </div>
        </div>
      </div>
      <div>1</div>
    </div>
  );
};

export default TopDoctor;
