import useSWR from 'swr';
import {
  IConsultationSummary,
  initialConsultation,
} from '../interfaces/ReportInterface';
import { endpoints } from '../utils/URLs';
import fetcher from '../utils/axiosFetcher';

export const useConsultationReport = () => {
  const { data, error } = useSWR<IConsultationSummary, any>(
    endpoints.reports.prescriptionReport,
    fetcher,
    {
      fallbackData: initialConsultation,
      errorRetryCount: 1,
      revalidateOnFocus: false,
    },
  );
  return {
    consultation: data,
    error: error,
  };
};
