import { useState } from 'react';
import { timeOfDayGreeting } from '../../../utils/dateTimeUtils';
import { IQuickTile } from '../../../interfaces/CommonInterface';
import { Link } from 'react-router-dom';
import { IPractitionerData } from '../../../interfaces/PractitionerInterface';

const QuickTiles = (practitionerData: IPractitionerData) => {
  const [quickTiles] = useState<IQuickTile[]>([
    {
      imgAlt: 'Write prescription',
      img: 'prescription-pad.svg',
      text: 'Write Prescription',
      link: '/prescriptions/new',
    },
    {
      imgAlt: 'Notes',
      img: 'note.svg',
      text: 'Investigation Report',
      link: '/prescriptions/investigation-report',
    },
    {
      imgAlt: 'clipboard',
      img: 'clipboard.svg',
      text: 'Medical Advice',
      link: '/prescriptions/medical-advice',
    },
    {
      imgAlt: 'wallet',
      img: 'wallet.svg',
      text: 'Make withdrawal',
      link: '/wallets',
    },
  ]);

  return (
    <div className='mt-4'>
      <div className='analytics-card1-metric grey-700 fs-20 fw-600'>
        {timeOfDayGreeting(
          `${practitionerData.firstName} ${practitionerData.lastName}`,
        )}
        ,
      </div>
      <div className='grey-600 fs-16 mt-2 fw-500'>
        What would you like to do today?
      </div>
      <div className='row mt-4'>
        {practitionerData.source === 2 ? (
          <div className='col-lg-3 col-md-4 col-sm-4 col-6 mt-3'>
            <Link
              to='/prescriptions/new/telemedicine'
              className='quick-tile-card border-success'
            >
              <img
                src={
                  require(`../../../assets/images/prescription-pad.svg`).default
                }
                className='img-fluid'
                alt='Write prescription'
              />
              <div className='paragraph-text m-0 align-items-middle fs-16'>
                Teleclinic Consult
              </div>
            </Link>
          </div>
        ) : (
          ''
        )}
        {quickTiles.map((tile, key) => (
          <div className='col-lg-3 col-md-4 col-sm-4 col-6 mt-3'>
            <Link to={tile.link} className='quick-tile-card'>
              <img
                src={require(`../../../assets/images/${tile.img}`)}
                className='img-fluid'
                alt='Write prescription'
              />
              <div className='paragraph-text m-0 align-items-middle fs-16'>
                {tile.text}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuickTiles;
