import { IPatientData } from '../../../interfaces/PatientInterface';

const PrescriptionSummaryHeader = ({
  patientData,
}: {
  patientData?: IPatientData;
}) => {
  return (
    <div className='pe-sm-1 ps-sm-1 pt-sm-4 pb-sm-4 pt-3 pb-3 row prescription-header'>
      <div className='col-4'>
        <img
          src={require('../../../assets/images/logo.svg').default}
          alt='Logo'
          width='64'
          height='50'
        />
      </div>
      <div className='col-8 text-end'>
        <div>
          Phone number: <span>{patientData?.phoneNumber}</span>
        </div>
        <div>
          Email: <span>{patientData?.email}</span>
        </div>
        <div>
          Address: <span>{patientData?.location}</span>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionSummaryHeader;
