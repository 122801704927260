export const dosageForm = [
  'Capsule(Cap)',
  'Tablets(Tab)',
  'Syrup (Syp)',
  'Suspension(Susp)',
  'Cream',
  'Ointment',
  'Vaginal Cream',
  'Pessary(Pess)',
  'Intravenous (IV)',
  'Intramuscular (IM)',
  'Sub-Cutaneous (SC)',
  'Ocular Drop (Occ. Drop)',
  'Ocular Ointment (Occ. Oint)',
  'Eardrop',
  'Nasal spray',
  'Inhalers ',
  'Suppositories',
  'Sublingual',
  'Dermal patch',
  'And Nasal drop',
];

export const frequencyList = [
  'tds',
  'bd',
  'Daily',
  'Once Daily',
  '12 hourly',
  '8 hourly',
  '6 hourly',
  'At Night',
];
