import { useState } from 'react';
import ButtonLoader from '../../components/common/ButtonLoader';
import axios from 'axios';
import { isFormValidated } from '../../../utils/formUtils';
import { endpoints } from '../../../utils/URLs';
import { IPrescriptionResponse } from '../../../interfaces/PrescriptionInterface';
import {
  IPaymentVerification,
  IPaymentVerificationDTO,
} from '../../../interfaces/PaymentInterface';
import { addToast } from '../../../utils/toastNotifications';

const PaymentVerification = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [prescriptionFound, setPrescriptionFound] = useState(false);
  const initialPaymentData: IPaymentVerification = {
    paymentReference: '',
    prescriptionCode: '',
  };
  const [paymentData, setPaymentData] = useState(initialPaymentData);

  const initialData: IPrescriptionResponse = {
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    phoneNumber: '',
    consultationCode: '',
    diagnosis: '',
    serviceCharge: 0,
    consultationId: 0,
    consultationType: 'Prescription',
    dateCreated: new Date().toISOString().split('T')[0],
  };
  const [prescription, setPrescription] = useState(initialData);

  const handleChange = (input: string) => (event: any) => {
    setPaymentData({
      ...paymentData,
      [input]: event.target.value,
    });
  };

  const submit = async (event: any) => {
    if (!isFormValidated('payment-verify-form')) {
      return;
    }

    event.preventDefault();

    if (prescriptionFound) {
      await verifyPayment();
    } else {
      await searchPrescription();
    }
  };

  const searchPrescription = async () => {
    const url = `${endpoints.consultations.mainUrl}/${paymentData.prescriptionCode}?paymentType=ErxServiceCharge`;

    setButtonLoading(true);

    try {
      const response = await axios.get(url);
      setPrescription(response.data);
      setPrescriptionFound(true);
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          addToast('Invalid prescription code', 'error');
        }
        console.error(error.response);
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const verifyPayment = async () => {
    //verify payment
    const url = `${endpoints.payments.mainUrl}/verify`;
    const payload: IPaymentVerificationDTO = {
      paymentReference: paymentData.paymentReference,
      email: prescription.email,
      phoneNumber: prescription.phoneNumber,
      totalAmount: prescription.serviceCharge,
      customerCode: prescription.consultationCode,
      paymentType: 'ErxServiceCharge',
      productCode: prescription.consultationCode,
      productType: 'Erx',
    };

    setButtonLoading(true);
    try {
      await axios.post(url, payload);
      addToast('Payment successfully verified', 'success');

      //load drugs and send sms
      await loadDrugAndSms(prescription.consultationCode);

      reset();
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 409) {
          addToast('Payment already verified');
        }
      }
      console.error(error.response);
    } finally {
      setButtonLoading(false);
    }
  };

  const loadDrugAndSms = async (prescriptionCode: string) => {
    const url = `${endpoints.consultations.mainUrl}/details/${prescriptionCode}?sendSms=true`;

    try {
      await axios.get(url);
      addToast('Drugs sent via sms to user');
    } catch (error) {
      console.error(error);
    }
  };

  const reset = () => {
    setPaymentData(initialPaymentData);
    setPrescriptionFound(false);
    setPrescription(initialData);
  };

  return (
    <div>
      <h5 className='text-center'>Verify payment</h5>
      <div className='row justify-content-center'>
        <div
          className='col-sm-6 col-md-6'
          style={{
            marginTop: '30px',
            backgroundColor: '#fff',
            padding: '20px',
          }}
        >
          <form id='payment-verify-form'>
            <fieldset disabled={buttonLoading}>
              <div className='form-group'>
                <label>Prescription code</label>
                <input
                  type='text'
                  className='form-control'
                  required
                  value={paymentData.prescriptionCode}
                  onChange={handleChange('prescriptionCode')}
                />
              </div>
              {prescriptionFound ? (
                <div className='form-group'>
                  <label>Paystack Payment Reference</label>
                  <input
                    id='paymentData-paymentReference'
                    type='text'
                    className='form-control'
                    required
                    value={paymentData.paymentReference}
                    onChange={handleChange('paymentReference')}
                  />
                </div>
              ) : (
                ''
              )}
              <br />
              <div>
                <button
                  type='submit'
                  className='btn btn-block request-btn-white'
                  onClick={submit}
                >
                  {prescriptionFound ? 'Verify Payment' : 'Search'}
                  {buttonLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentVerification;
