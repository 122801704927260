import { Link } from 'react-router-dom';

const PrescriptionBar = ({
  ctaText,
  ctaLink,
}: {
  ctaText: string;
  ctaLink: string;
}) => {
  return (
    <div className='row mt-4'>
      <div className='col-lg-3 col-sm-6 mt-3'>
        <Link to={ctaLink} className='quick-tile-card color-filter'>
          <img
            src={
              require(`../../../assets/images/prescription-pad-transparent.svg`)
                .default
            }
            className='img-fluid'
            alt={ctaText}
          />
          <div className='paragraph-text m-0 align-items-middle fs-16'>
            {ctaText}
          </div>
        </Link>
      </div>
      <div className='col-lg-7 mt-3 form-group'>
        <div className='form-group'>
          <span className='bi bi-search search-icon'></span>
          <input
            type='search'
            className='form-control ps-5 prescription-search'
          />
        </div>
      </div>
    </div>
  );
};

export default PrescriptionBar;
