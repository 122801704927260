export const PrescriptionStatus = [
  {
    key: 'Pending',
    value: 'Pending',
  },
  {
    key: 'Paid',
    value: 'Paid',
  },
  {
    key: 'Cancelled',
    value: 'Cancelled',
  },
];

export const NairaSymbol = '₦';

export const BankList = [
  {
    label: 'Access Bank Plc',
    value: 'Access Bank Plc',
  },
  {
    label: 'Citibank Nigeria Limited',
    value: 'Citibank Nigeria Limited',
  },
  {
    label: 'Ecobank Nigeria',
    value: 'Ecobank Nigeria',
  },
  {
    label: 'Fidelity Bank Plc',
    value: 'Fidelity Bank Plc',
  },
  {
    label: 'First Bank of Nigeria Limited',
    value: 'First Bank of Nigeria Limited',
  },
  {
    label: 'First City Monument Bank Limited',
    value: 'First City Monument Bank Limited',
  },
  {
    label: 'Guaranty Trust Bank Plc',
    value: 'Guaranty Trust Bank Plc',
  },
  {
    label: 'Heritage Bank Plc',
    value: 'Heritage Bank Plc',
  },
  {
    label: 'Jaiz Bank Plc',
    value: 'Jaiz Bank Plc',
  },
  {
    label: 'Keystone Bank Limited',
    value: 'Keystone Bank Limited',
  },
  {
    label: 'Kuda Bank',
    value: 'Kuda Bank',
  },
  {
    label: 'Mint Finex MFB',
    value: 'Mint Finex MFB',
  },
  {
    label: 'Mkobo MFB',
    value: 'Mkobo MFB',
  },
  {
    label: 'Polaris Bank Limited',
    value: 'Polaris Bank Limited',
  },
  {
    label: 'Providus Bank Limited',
    value: 'Providus Bank Limited',
  },
  {
    label: 'Rubies Bank',
    value: 'Rubies Bank',
  },
  {
    label: 'Stanbic IBTC Bank Plc',
    value: 'Stanbic IBTC Bank Plc',
  },
  {
    label: 'Standard Chartered',
    value: 'Standard Chartered',
  },
  {
    label: 'Sterling Bank Plc',
    value: 'Sterling Bank Plc',
  },
  {
    label: 'SunTrust Bank Nigeria Limited',
    value: 'SunTrust Bank Nigeria Limited',
  },
  {
    label: 'TAJBank Limited',
    value: 'TAJBank Limited',
  },
  {
    label: 'Titan Trust Bank Limited',
    value: 'Titan Trust Bank Limited',
  },
  {
    label: 'Union Bank of Nigeria Plc',
    value: 'Union Bank of Nigeria Plc',
  },
  {
    label: 'United Bank for Africa Plc',
    value: 'United Bank for Africa Plc',
  },
  {
    label: 'Unity Bank Plc',
    value: 'Unity Bank Plc',
  },
  {
    label: 'VFD MFB',
    value: 'VFD MFB',
  },
  {
    label: 'Wema Bank Plc',
    value: 'Wema Bank Plc',
  },
  {
    label: 'Zenith Bank Plc',
    value: 'Zenith Bank Plc',
  },
];

export const PrescriptionTabs = {
  allConsultations: 'All Consults',
  patient: 'Patient',
  notes: 'Notes',
  prescription: 'Prescription',
  investigationReport: 'Investigation Report',
  medicalAdvice: 'Medical Advice',
};

export const ConsultationTypeMap = [
  {
    key: 0,
    value: 'Prescription',
    text: 'Prescription',
  },
  {
    key: 0,
    value: 'InvestigationReport',
    text: 'Investigation Report',
  },
  {
    key: 0,
    value: 'MedicalAdvice',
    text: 'Medical Advice',
  },
];

export const ConsultationType = {
  prescription: 'Prescription',
  medicalAdvice: 'MedicalAdvice',
  investigation: 'InvestigationReport',
};

export const Genders = [
  {
    key: 0,
    value: 'Female',
    text: 'Female',
  },
  {
    key: 1,
    value: 'Male',
    text: 'Male',
  },
  {
    key: 2,
    value: 'Others',
    text: 'Others',
  },
];
