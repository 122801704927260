import { useCallback, useContext, useState } from 'react';
import PrescriptionTable from '../../components/prescription_view/PrescriptionTable';
import { AppContext } from '../../../context/AppContext';
import { IPrescriptionResponse } from '../../../interfaces/PrescriptionInterface';
import { IAppState } from '../../../interfaces/AppInterfaces';
import { endpoints } from '../../../utils/URLs';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';
import { useHistory } from 'react-router-dom';
import PatientSearch from '../../components/patient/PatientSearch';
import { IPatientData } from '../../../interfaces/PatientInterface';
import PatientInfo from '../../components/patient/PatientInfo';

const Patients = () => {
  const { dispatch }: { appState: IAppState; dispatch: any } =
    useContext(AppContext);

  const history = useHistory();
  const [prescriptionData, setPrescriptionData] = useState<
    IPrescriptionResponse[]
  >([]);

  const [patientData, setPatientData] = useState<IPatientData | undefined>();

  const [loading, setLoading] = useState(false);

  //reloads on key change
  const getConsultations = useCallback(async () => {
    let url = `${endpoints.consultations.mainUrl}/patients/${
      patientData!.patientId
    }`;

    setLoading(true);
    try {
      const response = await axios.get(url);

      setPrescriptionData(response.data);
    } catch (error) {
      addToast('Network error occured', 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [patientData]);

  const navigateToPrescription = (pageRoute: string) => {
    //save patient to context
    dispatch({
      type: 'SET_PATIENT_DATA',
      payload: patientData,
    });
    //navigate
    history.push(pageRoute);
  };

  const patientSearchCallback = (patient: IPatientData) => {
    setPatientData(patient);
  };

  return (
    <section className='row justify-content-center border prescription-summary p-0 p-sm-5'>
      <div className='col-12'>
        <h5>All Patients</h5>
        <div className='row mt-2'>
          <div className='col-lg-7 mt-3 form-group'>
            <PatientSearch callback={patientSearchCallback} />
          </div>
          <div>
            {patientData ? (
              <PatientInfo
                patientData={patientData}
                viewFunction={getConsultations}
                navigationFunction={navigateToPrescription}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        {prescriptionData.length > 0 ? (
          <PrescriptionTable
            prescriptionData={prescriptionData}
            isLoading={loading}
          />
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default Patients;
