import { IRoute } from './../interfaces/AppInterfaces';

//For routes with sub routes, add the sub-routes AFTER the main subroute
//e.g /profile has a sub route /profile/edit therefore, /profile/edit must be below /profile when declared below

const routes: IRoute[] = [
  {
    path: '/dashboard',
    name: 'Home',
  },
  {
    path: '/prescriptions',
    name: 'Prescriptions',
  },
  {
    path: '/prescriptions/drugs',
    name: 'Prescriptions Drugs',
  },
  {
    path: '/prescriptions/pay',
    name: 'Pay for Prescription',
  },
  {
    path: '/prescriptions/new',
    name: 'New Prescription',
  },
  {
    path: '/prescriptions/edit/:code?',
    name: 'Edit Prescription',
  },
  {
    path: '/profile',
    name: 'Profile',
  },
  {
    path: '/payments/verify',
    name: 'Verify Payment',
  },
  {
    path: '/wallets',
    name: 'Wallet Dashboard',
  },
  {
    path: '/wallets/withdrawal',
    name: 'Withdraw from Wallets',
  },
];

export default routes;
