import { useCallback, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { addToast } from '../../../utils/toastNotifications';
import '../../home.css';
import { Link } from 'react-router-dom';
import { Container, Navbar, Offcanvas } from 'react-bootstrap';

const HomeHeader = () => {
  const auth = useAuth();
  const searchParam = new URLSearchParams(window.location.search);
  const accessState = searchParam.get('access');
  const login = (event: any) => {
    event.preventDefault();
    loginOidc();
  };

  const loginOidc = useCallback(async () => {
    await auth
      .signinRedirect()
      .then((response) => {})
      .catch((error) => {
        addToast('Network error occured', 'error');
      });
  }, [auth]);

  useEffect(() => {
    //check localstorage if value exist
    const rememberMe = localStorage.getItem('rememberMe');
    //if value does not exist, save new value as false
    if (typeof rememberMe === 'undefined' || rememberMe == null) {
      localStorage.setItem('rememberMe', 'false');
      return;
    }

    if (accessState === 'false') {
      addToast('You do not have access to this application', 'error');

      //take users to the applications they have access to after a few seconds

      window.setTimeout(function () {
        window.location.href = process.env.REACT_APP_OIDC_AUTHORITY as string;
      }, 5000);
    }

    //else redirect to login if value is true
    if (rememberMe === 'true') {
      loginOidc();
    }
  }, [loginOidc, accessState]);

  return (
    <header className='container'>
      <Navbar key={'lg'} expand={'lg'} className=' mb-3'>
        <Container fluid>
          <Navbar.Brand href='#'>
            <a className='navbar-brand' href='/'>
              <img
                src={require('../../../assets/images/logo.svg').default}
                alt='Logo'
                width='64'
                height='50'
              />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'lg'}`}>
            <img
              src={require('../../../assets/images/menu.svg').default}
              alt='Nav Menu'
              width='26px'
              height='26px'
            />
          </Navbar.Toggle>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${'lg'}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${'lg'}`}
            placement='start'
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${'lg'}`}
              ></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ul className='navbar-nav ms-auto mb-2 mb-lg-0 text-center'>
                <li className='nav-item mt-4 mt-md-0'>
                  <a href='#about' className='nav-link'>
                    About ERx
                  </a>
                </li>
                <li className='nav-item  mt-4 mt-md-0'>
                  <a href='#how-it-works' className='nav-link'>
                    How it works
                  </a>
                </li>
                <li className='nav-item  mt-4 mt-md-0'>
                  <Link to='/register' className='btn primary-button d-block'>
                    Become A Provider
                  </Link>
                </li>
                <li className='nav-item  mt-4 mt-md-0'>
                  <button
                    className='outline-primary-button d-block w-100'
                    onClick={login}
                  >
                    Sign In
                  </button>
                </li>
              </ul>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  );
};

export default HomeHeader;
