import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import { useAuth } from 'react-oidc-context';
import AddToHomeScreen from '../common/AddToHomeScreen';

const Sidebar = () => {
  const { dispatch, appState } = useContext(AppContext);
  const auth = useAuth();

  const setActiveNav = (event: any) => {
    //remove all active navs
    let navLinks = document.getElementsByClassName('nav-link');
    for (let i = 0; i < navLinks.length; i++) {
      navLinks[i].classList.remove('active');
    }
    //set active for the current one
    event.target.closest('.nav-link').classList.add('active');

    //scroll to top
    let scrollHere: any = document.getElementById('scroll-here');
    scrollHere.scrollIntoView();

    //close side menu if open
    let sideBar: any = document.getElementById('sidebarMenu');
    sideBar.classList['remove']('show');
  };

  const logOut = (event: any) => {
    dispatch({
      type: 'LOG_OUT',
    });
    auth
      .signoutRedirect()
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <nav
      id='sidebarMenu'
      className='col-md-3 col-lg-2 d-md-block sidebar collapse border-top'
    >
      <div className='sidebar-sticky pt-3 border-top'>
        <ul className='nav flex-column border-top'>
          <li className='nav-item'>
            <Link
              to='/dashboard'
              className='nav-link active d-flex fw-500 fs-14'
              onClick={setActiveNav}
            >
              <img
                src={require(`../../../assets/images/build-block.svg`).default}
                className='img-fluid'
                alt='building block'
              />
              <span className='ms-2'>Dashboard</span>
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/profile'
              className='nav-link d-flex fs-14 fw-500'
              onClick={setActiveNav}
            >
              <img
                src={require(`../../../assets/images/patient-card.svg`).default}
                className='img-fluid'
                alt='patient card'
              />
              <span className='ms-2'>Profile</span>
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/patients'
              className='nav-link d-flex fs-14 fw-500'
              onClick={setActiveNav}
            >
              <img
                src={require(`../../../assets/images/user.svg`).default}
                className='img-fluid'
                alt='stethoscope'
              />
              <span className='ms-2'>Patients</span>
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/prescriptions'
              className='nav-link d-flex fs-14 fw-500'
              onClick={setActiveNav}
            >
              <img
                src={
                  require(`../../../assets/images/stethoscope-2.svg`).default
                }
                className='img-fluid'
                alt='stethoscope'
              />
              <span className='ms-2'>Prescriptions</span>
            </Link>
          </li>

          <li className='nav-item'>
            <Link
              to='/wallets'
              className='nav-link fs-14 d-flex fw-500'
              onClick={setActiveNav}
            >
              <img
                src={require(`../../../assets/images/wallet-3.svg`).default}
                className='img-fluid'
                alt='wallet'
              />
              <span className='ms-2'>Wallets</span>
            </Link>
          </li>

          {appState.practitioner &&
          appState.practitioner.email.includes('@wellahealth.com') ? (
            <li className='nav-item'>
              <Link
                to='/payments/verify'
                className='nav-link fs-14 d-flex fw-500'
                onClick={setActiveNav}
              >
                <img
                  src={require(`../../../assets/images/wallet-3.svg`).default}
                  className='img-fluid'
                  alt='wallet'
                />
                <span className='ms-2'>Verify Payments</span>
              </Link>
            </li>
          ) : (
            ''
          )}
        </ul>
        <div className='nav-bottom'>
          <ul className='nav flex-column'>
            <li className='nav-item ms-3 d-block d-sm-none'>
              <AddToHomeScreen />
            </li>
            <li className='nav-item'>
              <Link
                to='/'
                type='button'
                onClick={logOut}
                className='nav-link fs-14 d-flex fw-500'
                style={{ width: '100%' }}
              >
                <span className='bi bi-box-arrow-left'></span>
                <span className='ms-2'>Sign out</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
