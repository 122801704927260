import { IDrugs } from '../../../interfaces/PrescriptionInterface';

const PrescriptionTab = ({
  drugData,
  deleteDrug,
  editDrug,
}: {
  drugData: IDrugs;
  deleteDrug: Function;
  editDrug: Function;
}) => {
  return (
    <div>
      <div className='row mt-2'>
        <div className='text-start col-md-3 text-danger  d-none d-md-block mt-2 mt-sm-0'>
          <button
            className='btn btn-sm btn-default fs-12  text-danger'
            onClick={() => deleteDrug(drugData.id)}
          >
            <span className='bi bi-x-circle me-2'></span>Remove
          </button>
        </div>
        <div className='col-md-7 fs-14 grey-900 '>
          <div className='fw-500'>{drugData.name}</div>
          <div>
            {drugData.dose}; {drugData.strength}; {drugData.frequency};{' '}
            {drugData.duration}
          </div>
        </div>
        <div className='text-start col-md-2 col-4 mt-2 mt-sm-0'>
          <button
            className='btn btn-sm  primary-7 fs-12 '
            onClick={() => editDrug(drugData.id)}
          >
            Edit<span className='ms-1 bi bi-pencil-square'></span>
          </button>
        </div>
        <div className='text-start col-md-3 col-4 mt-2 mt-sm-0 d-block d-md-none'>
          <button
            className='btn btn-sm text-danger fs-12'
            onClick={() => deleteDrug(drugData.id)}
          >
            <span className='bi bi-x-circle me-2'></span>Remove
          </button>
        </div>
      </div>
      <div className='mt-3 border-top'></div>
    </div>
  );
};

export default PrescriptionTab;
