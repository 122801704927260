import { IProps } from '../../../interfaces/AppInterfaces';
import { endpoints } from '../../../utils/URLs';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import { addToast } from '../../../utils/toastNotifications';
import { ITelemedicineRequestDTO } from '../../../interfaces/TelemedicineRequestsInterface';
import Loader from '../../components/common/Loader';
import ButtonLoader from '../../components/common/ButtonLoader';

const TelemedicineRequests = (props: IProps): JSX.Element => {
  //https://stackoverflow.com/a/39672914/2929906
  const { appState, dispatch } = useContext(AppContext);
  const [statusId, setStatusId] = useState(0);

  const [requests, setRequests] = useState<
    ITelemedicineRequestDTO[] | undefined
  >();

  const getRequests = async () => {
    let url = `${endpoints.telemedicine.mainUrl}/pending`;

    try {
      const response = await axios.get(url);
      setRequests(response.data);
    } catch (error) {
      addToast('Network error occured', 'error');
      console.error(error);
    }
  };

  const updateStatus =
    (selectedData: ITelemedicineRequestDTO) => async (event: any) => {
      event.preventDefault();

      const practitionerId = appState.practitioner.practitionerId;

      const url = `${endpoints.telemedicine.mainUrl}/status`;
      const payload = {
        requestId: selectedData.requestId,
        status: 'InConsultation',
        practitionerId: practitionerId,
      };
      setStatusId(selectedData.requestId);
      try {
        await axios.put(url, payload);

        //set patient details in context

        dispatch({
          type: 'SET_TELEMEDICINE_REQUEST',
          payload: selectedData,
        });

        getRequests();
      } catch (error) {
        addToast('An error occured when accepting request');
      } finally {
        setStatusId(0);
      }
    };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <div>
      <h5
        className='text-center'
        style={{ marginTop: '20px', fontWeight: 500, color: '#191489' }}
      >
        All Telemedicine Requests
      </h5>
      <br />
      {requests ? (
        <div>
          {requests.length > 0 ? (
            <div className='row justify-content-center'>
              {requests.map((data, key) => (
                <div key={key} className='col-sm-5 mt-2 mb-2'>
                  <div className='card prescription-card'>
                    <div className='card-body'>
                      <h6 className='card-title' style={{ fontWeight: 600 }}>
                        {data.patientFirstName} {data.patientLastName}
                      </h6>
                      <div className='row mt-4 card-text prescription-card-text'>
                        <div className='col-6'> {data.phoneNumber} </div>
                        <div className='col-6 text-right'>
                          {new Date().toLocaleDateString()}
                        </div>
                      </div>
                      {/* <div className='card-text'>Drugs: {data.drugCount}</div> */}
                      <div className='row mt-4 card-text prescription-card-text'>
                        <div className='col-4'>{data.status}</div>
                        <div
                          className='col-8 text-right'
                          style={{ fontWeight: 600 }}
                        >
                          <button
                            className='btn btn-success'
                            onClick={updateStatus(data)}
                          >
                            Accept
                            {statusId === data.requestId ? (
                              <ButtonLoader />
                            ) : (
                              ''
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='text-center mt-5'>No pending requests</div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TelemedicineRequests;
