import { IPractitionerData } from '../../../interfaces/PractitionerInterface';
// import { copyText } from '../../utils/textUtil';
import NameBadge from '../common/NameBadge';

const DoctorProfile = (userInfo: IPractitionerData) => {
  return (
    <section className='row'>
      <div className='col-lg-4 col-md-6 col-sm-6 mt-2'>
        <div className=' doctor-profile d-flex'>
          {/* TODO: Show profile image or name badge  */}
          {/* <img
            src={require('../../assets/images/small-doctor.svg').default}
            className='img-fluid'
            alt='Doctor'
          /> */}
          <NameBadge
            firstName={userInfo?.firstName}
            lastName={userInfo.lastName}
          />
          <div className='paragraph-text m-0 fs-28 fw-700 grey-700 doctor-name align-items-middle'>
            {userInfo.firstName} {userInfo.lastName}
          </div>
        </div>
      </div>
      {/* TODO: ENable when when we have a profile page */}
      {/* <div className='col-lg-4 col-md-6 col-sm-6 mt-2'>
        <div className='quick-tile-card d-flex '>
          <img
            src={require('../../assets/images/link-2.svg').default}
            className='img-fluid'
            alt='Doctor'
          />
          <div className='align-items-middle doctor-link'>
            https://erx.ng/{userInfo.firstName}-{userInfo.lastName}
          </div>
          <button
            className='btn btn-primary'
            onClick={() =>
              copyText(
                `https://erx.ng/${userInfo.firstName}-${userInfo.lastName}`,
              )
            }
          >
            Copy
          </button>
        </div>
        <div className='ps-3 fs-12'>Copy and share your profile link.</div>
      </div> */}
    </section>
  );
};

export default DoctorProfile;
