import { Link } from 'react-router-dom';
import { IPrescriptionResponse } from '../../../interfaces/PrescriptionInterface';
import { formatDisplayDate } from '../../../utils/dateTimeUtils';
import NoTableData from '../common/NoTableData';
import TableLoader from '../common/TableLoading';

const PrescriptionTable = ({
  prescriptionData,
  isLoading,
}: {
  prescriptionData: IPrescriptionResponse[];
  isLoading: boolean;
}) => {
  return (
    <div className='table-responsive mt-4'>
      <table className='table table-striped table-hover prescription-table'>
        <thead>
          <tr>
            <th>SN</th>
            <th>Date</th>
            <th>Patient name</th>
            <th>Phone number</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        {isLoading ? (
          <TableLoader />
        ) : (
          <tbody>
            {prescriptionData.length === 0 && <NoTableData />}
            {prescriptionData.map((data, key) => (
              <tr key={data.consultationId}>
                <td>{data.consultationId}</td>
                <td>{formatDisplayDate(data.dateCreated)}</td>
                <td>
                  {data.firstName} {data.lastName}
                </td>
                <td>{data.phoneNumber}</td>
                <td>
                  <span
                    className={`status-pill status-pill-${data.status.toLowerCase()} badge rounded-pill text-bg-success`}
                  >
                    {data.status}
                  </span>
                </td>
                <td>
                  <Link
                    to={`/prescriptions/view/${data.consultationCode}`}
                    className='prescription-link'
                  >
                    <span className='bi bi-clipboard-data me-1'></span>
                    View Report
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <hr />
      <div className='float-end d-flex'>
        <div className='btn'>10 - 25</div>
        <button className='btn'>
          <span className='bi bi-caret-left'></span>
        </button>
        <button className='btn'>
          <span className='bi bi-caret-right'></span>
        </button>
      </div>
    </div>
  );
};

export default PrescriptionTable;
