import { useEffect } from 'react';

//https://stackoverflow.com/a/42234988/2929906

//use this as ref in any component to handle when clicking outside that component
//Note: wrap the entire component in a div and use the ref on the div
//example use:

// export default function OutsideAlerter() {
//     const callback = () => {console.log('Hello world');}
//     const wrapperRef = useRef(null);
//     useOutsideAlerter(wrapperRef, callback);

//     return <div ref={wrapperRef}>Hello</div>;
// }

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref: any, callback?: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}
