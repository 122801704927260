import axios from 'axios';
// import { IPaymentVerificationDTO } from '../../interfaces/PaymentInterface';
import { IProps } from '../../../interfaces/AppInterfaces';
import {
  IPrescriptionResponse,
  initialPrescriptionResponse,
} from '../../../interfaces/PrescriptionInterface';
import { useState, FormEvent, useEffect, useCallback } from 'react';
import { isFormValidated } from '../../../utils/formUtils';
import { endpoints } from '../../../utils/URLs';
import { usePaystackPayment } from 'react-paystack';
import { addToast } from '../../../utils/toastNotifications';
import Loader from '../../components/common/Loader';
import NotFound from '../../components/common/NotFound';
import HeaderLogo from '../../components/dashboard/HeaderLogo';
import { NairaSymbol } from '../../../utils/mappings';

const PatientPrescriptions = (props: IProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [isFound, setIsFound] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [prescription, setPrescription] = useState(initialPrescriptionResponse);

  const [config, setConfig] = useState({
    reference: `ERX-${new Date().getTime().toString()}`,
    email: '',
    amount: 0,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY as string,
    split_code: process.env.REACT_APP_PAYSTACK_SPLIT_CODE as string,
    metadata: {
      custom_fields: [
        {
          display_name: 'Recurring Payment',
          variable_name: 'Recurring',
          value: 'false',
        },
      ],
    },
  });

  const [prescriptionCode, setPrescriptionCode] = useState('');

  // let verifyCount = 0;

  const initializePayment = usePaystackPayment(config);

  const paymentSuccessful = (paymentData: any) => {
    // Implementation for whatever you want to do with reference and after success call.
    props.history.push('/prescriptions/payment/success');
  };

  const paymentClosed = (response: any) => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    //set new reference for new transaction
    setConfig({
      ...config,
      reference: `ERX-${new Date().getTime().toString()}`,
    });
  };

  // const verifyPayment = (paymentReference: string) => {
  //   setConfig({
  //     ...config,
  //     reference: `ERX-${new Date().getTime().toString()}`,
  //   });

  //   addToast('Verifying your payment', 'info');

  //   //verify payment
  //   const url = `${endpoints.payments.mainUrl}/verify`;
  //   const payload: IPaymentVerificationDTO = {
  //     paymentReference: paymentReference,
  //     email: config.email,
  //     phoneNumber: prescription.patientPhoneNumber,
  //     customerCode: prescription.prescriptionCode,
  //     totalAmount: prescription.serviceCharge,
  //     productType: 'Erx',
  //     productCode: prescription.prescriptionCode,
  //     paymentType: 'ErxServiceCharge',
  //   };

  //   setLoading(true);

  //   axios
  //     .post(url, payload)
  //     .then((response) => {
  //       props.history.push(
  //         `/prescriptions/drugs?code=${prescription.prescriptionCode}&sendsms=true`,
  //       );
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       if (error.response) {
  //         if (error.response.status === 422) {
  //           if (verifyCount < 3) {
  //             addToast(
  //               'An error occured verifying your payment. Retrying..',
  //               'error',
  //             );
  //             verifyCount++;
  //             verifyPayment(paymentReference);
  //           } else {
  //             addToast(
  //               'Transaction could not be verified. A message has been sent to admin',
  //               'info',
  //               false,
  //             );
  //             sendEmail(paymentReference);
  //           }
  //         } else if (error.response.status === 404) {
  //           addToast('Prescription code not found', 'error');
  //         }
  //       } else {
  //         addToast('An error occured', 'error');
  //       }
  //       //if error, verify payment once more
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // const sendEmail = (transactionReference: string) => {
  //   const url = endpoints.email.mainUrl;
  //   const payload = {
  //     message: `A payment could not be verified. Please verify manually. <br />Transaction reference: ${transactionReference}`,
  //     title: 'ERX Payment Verification',
  //     mailTo: 'bayo@wellahealth.com',
  //   };
  //   axios
  //     .post(url, payload)
  //     .then((response) => {})
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const handleChange = (input: string) => (event: any) => {
    // setConfig({
    //   ...config,
    //   [input]: event.target.value,
    // });

    //set the paystack metadata?
    let metadata = {
      custom_fields: [
        {
          display_name: 'Order Id',
          variable_name: 'OrderId',
          value: prescription.consultationCode,
        },
        {
          display_name: 'Phone Number',
          variable_name: 'PhoneNumber',
          value: prescription.phoneNumber,
        },
      ],
    };
    setConfig({
      ...config,
      metadata: metadata,
      [input]: event.target.value,
    });
  };

  const payAmount = async (event: FormEvent) => {
    if (!isFormValidated('pay-form')) {
      return;
    }
    event.preventDefault();
    setButtonLoading(true);
    initializePayment({ onClose: paymentClosed, onSuccess: paymentSuccessful });
  };

  const loadPayment = useCallback(async () => {
    const searchParam = new URLSearchParams(window.location.search);

    const prescriptionCode = searchParam.get('code');

    if (prescriptionCode === null) {
      setIsFound(false);
      return;
    }

    const url = `${endpoints.payments.mainUrl}/${prescriptionCode}?paymentType=ErxServiceCharge`;

    try {
      const response = await axios.get(url);

      //if payment is made, navigate to drugs
      if (response.data.length > 0) {
        props.history.push(
          `/prescriptions/drugs?code=${prescriptionCode}&sendsms=false`,
        );
      }
      setIsFound(true);
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          setIsFound(true);
        }
      } else {
        addToast('Network error occcured', 'error');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [props.history]);

  const loadPrescriptions = useCallback(async () => {
    const searchParam = new URLSearchParams(window.location.search);

    const patientCode = searchParam.get('code');

    if (patientCode === null) {
      setIsFound(false);
      return;
    }
    setPrescriptionCode(patientCode);
    const url = `${endpoints.patients.mainUrl}/consultations/${patientCode}`;
    setLoading(true);
    try {
      const response = await axios.get(url);
      const parsedResponse: IPrescriptionResponse = response.data;

      //if the serviceCharge is 0, redirect user to their drugs

      if (parsedResponse.serviceCharge <= 1) {
        props.history.push(
          `/prescriptions/drugs?code=${parsedResponse.consultationCode}`,
        );
      }

      setPrescription(response.data);

      //set paystack payment

      setConfig((f) => {
        return { ...f, amount: parsedResponse.serviceCharge * 100 };
      });

      loadPayment();
    } catch (error: any) {
      //if no prescription, return error
      if (error.response) {
        if (error.response.status === 404) {
          setLoading(false);
          setIsFound(false);
        }
      } else {
        addToast('Network error occured', 'error');
      }

      console.error(error);
    }
  }, [loadPayment, props.history]);

  useEffect(() => {
    loadPrescriptions();
  }, [loadPrescriptions]);

  return (
    <div>
      <HeaderLogo />
      <section
        className='container'
        style={{ marginTop: '70px', marginBottom: '50px' }}
      >
        <div className='row justify-content-center'>
          <div className='col-md-6 col-sm-8 center-section'>
            {loading ? (
              <Loader />
            ) : (
              <div>
                {isFound ? (
                  <form id='pay-form' className='row'>
                    <fieldset disabled={buttonLoading}>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <div>Hello, {prescription.firstName}</div>
                          <br />
                          <div>
                            Prescription code :
                            <strong>{prescriptionCode}</strong>
                          </div>
                          <br />
                          <div>
                            Your doctor has prescribed some drugs for you.
                          </div>
                          <br />
                          <div>
                            Pay your doctor
                            <strong className='ms-2 me-2'>
                              {NairaSymbol}
                              {prescription.serviceCharge}
                            </strong>
                            for consultation to view your drugs
                          </div>
                          <br />
                          <label>Email address</label>
                          <input
                            type='email'
                            className='form-control'
                            aria-describedby='phoneHelp'
                            value={config.email}
                            onChange={handleChange('email')}
                            required
                          />
                          <small
                            id='phoneHelp'
                            className='form-text text-muted'
                          >
                            We'll never share your email with anyone else.
                          </small>
                        </div>
                        <br />
                        <div className='d-block'>
                          <button
                            type='submit'
                            className='btn btn-block btn-success'
                            onClick={payAmount}
                          >
                            Pay
                            <strong className='ms-2'>
                              {NairaSymbol}
                              {prescription.serviceCharge}
                            </strong>
                            {buttonLoading ? (
                              <span
                                className='spinner-border spinner-border-sm btn-spinner'
                                role='status'
                                aria-hidden='true'
                              ></span>
                            ) : (
                              ''
                            )}
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                ) : (
                  <NotFound prescriptionCode={prescriptionCode} />
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PatientPrescriptions;
