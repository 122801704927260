import { useEffect, useState } from 'react';

export function useAddToHomescreenPrompt() {
  const [prompt, setState] = useState<any | undefined>();

  const promptToInstall = () => {
    if (prompt) {
      var thisPrompt = prompt as any;
      return thisPrompt.prompt();
    }

    return;
    //  Promise.reject(
    //   new Error(
    //     'Tried installing before browser sent "beforeinstallprompt" event',
    //   ),
    // );
  };

  useEffect(() => {
    const ready = (e: any) => {
      e.preventDefault();
      setState(e);
    };

    window.addEventListener('beforeinstallprompt', ready);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready);
    };
  }, []);

  return [prompt, promptToInstall];
}
