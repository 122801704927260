const ScreenLoading = () => {
  return (
    <div id='logo-loader' className='text-center logo-loader'>
      {/* <img
        className='img-fluid logo-loader-img'
        style={{ width: '50%' }}
        alt='logo loading'
        src={require('../../../assets/images/logo.svg').default}
      /> */}
      <div
        style={{ width: '3rem', height: '3rem' }}
        className='spinner-border spinner-border-lg me-auto ms-auto'
        role='status'
        aria-hidden='true'
      ></div>
    </div>
  );
};
export default ScreenLoading;
