import { Link } from 'react-router-dom';

const IncomeOverviewCard = () => {
  return (
    <div className='analytics-card1'>
      <div className='analytics-card1-inner'>
        <div>
          <div className='fs-20 fw-600 analytics-card1-header grey-600'>
            Income this month
          </div>
          <div className='mt-2 fw-600 fs-32 grey-700 analytics-card1-header'>
            {0}
          </div>
          <div className='mt-1 d-flex'>
            <img
              src={
                require(`../../../../assets/images/icon-chart-up.svg`).default
              }
              className='img-fluid'
              alt='chart'
            />
            <div className='fw-500 grey-600 fs-12 text-spacer1'>
              {0} %<span className='ms-2'>This month</span>
            </div>
          </div>
        </div>
        <img
          src={require(`../../../../assets/images/wallet-2.svg`).default}
          className='img-fluid'
          alt='Write prescription'
        />
      </div>
      <Link to='/wallets' className='mt-4 analytics-card1-inner'>
        <div className='fs-12 fw-500 primary-7'>View Cash Flow</div>
        <img
          src={require(`../../../../assets/images/arrow-right.svg`).default}
          alt='arrow-right'
          className='img-fluid'
        />
      </Link>
    </div>
  );
};

export default IncomeOverviewCard;
