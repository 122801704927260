import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { isFormValidated } from '../../../utils/formUtils';
import {
  IWalletAccountSummary,
  walletData,
} from '../../../interfaces/WalletInterface';
import { IAppState } from '../../../interfaces/AppInterfaces';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URLs';

const WithdrawalPage = ({ history }: any) => {
  const { appState }: { appState: IAppState } = useContext(AppContext);
  const [walletSummary, setWalletSummary] =
    useState<IWalletAccountSummary>(walletData);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const changeAmount = (event: any) => {
    const amount = parseFloat(event.target.value);

    if (amount > walletSummary.walletBalance) {
      addToast('Withdrawal amount exceeds wallet balance');
    }

    setWithdrawalAmount(amount);
  };

  const requestWithdrawal = async (event: any) => {
    if (!isFormValidated('withdrawal-request')) {
      return;
    }

    event.preventDefault();

    if (withdrawalAmount > walletSummary.walletBalance) {
      addToast('Withdrawal amount exceeds wallet balance');
      return;
    }
    const practitionerId = appState.practitioner?.practitionerId;

    const payload = {
      amount: withdrawalAmount,
      practitionerId: practitionerId,
    };
    const url = `${endpoints.practitionerWallet.mainUrl}/withdrawals`;
    setIsLoading(true);
    try {
      await axios.post(url, payload);
      addToast('Withdrawal request successful', 'success');
      history.push('/wallets');
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 404 || error.response.status === 422) {
          addToast(error.response.data.message, 'error');
        } else {
          addToast('server error occured', 'error');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const applicationState = appState as IAppState;

    let wallet = applicationState.wallet;

    if (
      wallet === null ||
      typeof wallet === 'undefined' ||
      wallet?.bankAccountNumber === ''
    ) {
      //push state
      history.push('/wallets');
      return;
    }
    wallet = wallet as IWalletAccountSummary;
    setWalletSummary(wallet);
  }, [history, appState]);

  return (
    <div>
      <h5 className='text-center' style={{ color: '#191489' }}>
        Request for Withdrawal
      </h5>
      <br />
      <h4 className='text-center'>
        Available Balance:
        <span
          className={
            walletSummary.walletBalance >= 0 ? 'text-success' : 'text-danger'
          }
        >
          ₦ {walletSummary.walletBalance}
        </span>
      </h4>
      <div className='row justify-content-center'>
        <div
          className='col-sm-6 col-md-6'
          style={{
            marginTop: '30px',
            backgroundColor: '#fff',
            padding: '20px',
          }}
        >
          <form id='withdrawal-request'>
            <fieldset disabled={isLoading}>
              <div className='form-group mt-3'>
                <label>Account Name</label>
                <input
                  type='text'
                  className='form-control'
                  disabled
                  value={walletSummary.bankAccountName}
                />
              </div>
              <div className='form-group mt-3'>
                <label>Account Number</label>
                <input
                  type='text'
                  className='form-control'
                  disabled
                  value={walletSummary.bankAccountNumber}
                />
              </div>
              <div className='form-group mt-3'>
                <label>Bank Name</label>
                <input
                  type='text'
                  className='form-control'
                  disabled
                  value={walletSummary.bankName}
                />
              </div>
              <div className='form-group mt-3'>
                <label>
                  Amount (<small>Minimum of ₦1000</small>)
                </label>
                <input
                  type='number'
                  className='form-control'
                  required
                  min={1000}
                  max={100000}
                  onChange={changeAmount}
                />
              </div>

              <div className='mt-4'>
                <button
                  type='submit'
                  className='btn btn-block request-btn-white'
                  style={{ backgroundColor: '#191489' }}
                  onClick={requestWithdrawal}
                >
                  Request for Withdrawal
                  {isLoading ? (
                    <span
                      className='spinner-border spinner-border-sm btn-spinner'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  ) : (
                    ''
                  )}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalPage;
