const ConsultationMetric = ({
  count,
  text,
  percent,
  icon,
}: {
  count: number;
  text: string;
  percent: number;
  icon: string;
}) => {
  return (
    <div className='analytics-card1-inner align-items-center mt-3 text-start'>
      <div className=''>
        <img
          src={require(`../../../../assets/images/${icon}`)}
          className='img-fluid'
          alt='icon'
        />
        <span className='grey-700 fs-12 text-spacer1 ms-2'></span>
        {count}
      </div>
      <div className='grey-600 fs-12'>{text}</div>
      <div className='fs-16 fw-500'>{percent}%</div>
    </div>
  );
};

export default ConsultationMetric;
