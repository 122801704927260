import { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  IDrugData,
  IDrugs,
  initialDrugData,
} from '../../../interfaces/PrescriptionInterface';
import PrescriptionTab from './PrescriptionTab';
import Select, { createFilter } from 'react-select';
import { endpoints } from '../../../utils/URLs';
import axios from 'axios';
import { dosageForm, frequencyList } from '../../../utils/drugUtils';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';

const PrescriptionPad = ({ callback }: { callback: Function }) => {
  const [drugSearch, setDrugSearch] = useState<IDrugData[]>();
  const [showMore, setShowMore] = useState(false);

  const [drugDuration, setDrugDuration] = useState({
    unit: 1,
    total: 7,
  });

  const [selectDrugList, setSelectDrugList] = useState<any[]>();
  const [drugData, setDrugData] = useState(initialDrugData);
  const [drugList, setDrugList] = useState<IDrugs[]>([]);

  const handleDrugChange = (input: string) => (event: any) => {
    setDrugData({
      ...drugData,
      [input]: event.target.value,
    });
  };

  const drugChange = (selectedValue: any) => {
    setDrugData({
      ...drugData,
      name: selectedValue.value,
    });
  };

  const clickShowMore = () => {
    setShowMore(true);
    setDrugData({
      ...drugData,
      name: '',
    });
  };

  const handleDurationChange = (input: string) => (event: any) => {
    setDrugDuration({
      ...drugDuration,
      [input]: event.target.value,
    });
  };

  const addDrugToList = (event: FormEvent) => {
    const formName = `prescription-pad`;
    if (!isFormValidated(formName) || drugData.name === '') {
      addToast('Please fill all fields marked as *', 'error');
      return;
    }

    event.preventDefault();

    //if id > 0, we are editing
    if (drugData.id > 0) {
      //delete existing medication with the id
      const existingMedications = drugList.filter((x) => x.id !== drugData.id);

      drugData.duration = `${drugDuration.unit}/${drugDuration.total}`;

      //add updated medication
      existingMedications.push(drugData);
      setDrugList(existingMedications);

      //reset form
      setDrugData(initialDrugData);

      addToast('Drug updated', 'success');

      return;
    }

    //else, we are adding new drug
    let updatedDrugs = drugList;

    //drugData.id = updatedDrugs.length + 1;
    let drugId = updatedDrugs.length + 1;

    const lastDrugEntry = drugList[drugList.length - 1];

    if (lastDrugEntry) {
      drugId = lastDrugEntry.id;
    }

    drugData.id = drugId;
    //add duration info
    drugData.duration = `${drugDuration.unit}/${drugDuration.total}`;

    updatedDrugs.push(drugData);
    setDrugList(updatedDrugs);

    //reset
    setDrugData(initialDrugData);

    addToast('Drug added', 'success');

    setShowMore(false);
  };

  const deleteDrugFromList = (drugId: number) => {
    const existingDrug = drugList.filter((x) => x.id !== drugId);
    drugList.length = existingDrug.length;
    //add updated medication
    setDrugList(existingDrug);
    addToast('Medication deleted', 'success');
  };

  const editDrugFromList = (drugId: number) => {
    //get the medication using id
    const drug = drugList.filter((x) => x.id === drugId)[0];

    if (drug === null || typeof drug === 'undefined') {
      return;
    }

    setDrugData(drug);

    //split duration
    const durationParts = drug.duration.split('/');
    setDrugDuration({
      ...drugDuration,
      unit: parseInt(durationParts[0]),
      total: parseInt(durationParts[1]),
    });
  };

  const clearForm = (event: FormEvent) => {
    event.preventDefault();
    setDrugData(initialDrugData);
  };

  const saveAndProceed = (event: FormEvent) => {
    if (drugData.name !== '') {
      //if data present, check if form valid
      const formName = `prescription-pad`;
      if (!isFormValidated(formName) || drugData.name === '') {
        addToast('Please fill all fields marked as *', 'error');
        return;
      }
      event.preventDefault();
      //if form valid, add form data to drug list
      addDrugToList(event);
    }

    event.preventDefault();

    //if no drugs in list
    if (drugList.length === 0) {
      addToast('Please add atleast one medication', 'error');
      return;
    }
    callback(drugList);
  };

  const loadDrugs = useCallback(async () => {
    //load from localStorage if available, else load from Api and store in local

    let savedDrugs = localStorage.getItem('drugList');
    if (savedDrugs && savedDrugs !== 'undefined') {
      const parsedDrugs = JSON.parse(savedDrugs);
      setDrugSearch(parsedDrugs);
      const drugArray = parsedDrugs.map((drug: any, index: any) => {
        return {
          id: index,
          label: drug.drugName,
          value: drug.drugName,
        };
      });
      setSelectDrugList(drugArray);
      return;
    }

    const url = endpoints.priceList.mainUrl;
    try {
      const response = await axios.get(url);
      localStorage.setItem('drugList', JSON.stringify(response.data));
      const parsedData = response.data.data as IDrugData[];
      setDrugSearch(parsedData);
      const drugArray = parsedData?.map((drug, index) => {
        return {
          id: drug.pricelistId,
          label: drug.drugName,
          value: drug.drugName,
        };
      });
      setSelectDrugList(drugArray);

      setSelectDrugList(drugArray);
    } catch (error: any) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    loadDrugs();
  }, [loadDrugs]);

  return (
    <section className='mt-5'>
      <div className='mt-3'>
        {drugList.map((drug) => (
          <PrescriptionTab
            key={drug.id}
            drugData={drug}
            deleteDrug={deleteDrugFromList}
            editDrug={editDrugFromList}
          />
        ))}
      </div>
      <form id='prescription-pad'>
        <fieldset className='row'>
          <div
            className={
              showMore ? `form-group d-none mt-3 ` : 'form-group d-block mt-3'
            }
          >
            <div>
              <label>
                Drug Name <span className='text-danger'>*</span>
              </label>
              <button
                type='button'
                onClick={clearForm}
                className='float-end btn btn-sm btn-outline-danger'
              >
                Clear form
              </button>
            </div>
            <Select
              options={selectDrugList}
              filterOption={createFilter({ ignoreAccents: false })}
              onChange={drugChange}
              noOptionsMessage={() => (
                <button className='btn' onClick={clickShowMore}>
                  Drug Not Found. Click to Type Drug
                </button>
              )}
              value={{ label: drugData.name, value: drugData.name }}
            />
          </div>
          <div
            className={
              showMore ? `form-group d-block mt-3` : 'form-group d-none mt-3'
            }
          >
            <label>
              Drug Name<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              required
              className='form-control'
              placeholder='Input Drug Name...'
              value={drugData.name}
              onChange={handleDrugChange('name')}
              list='druglist'
            />
            <datalist id='druglist'>
              {drugSearch?.map((drug, key) => (
                <option key={key}>{drug.drugName}</option>
              ))}
            </datalist>
          </div>
          <div className='form-group mt-3 col-6'>
            <label>
              Drug form <span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              required
              className='form-control'
              value={drugData.dose}
              list='dosage-list'
              onChange={handleDrugChange('dose')}
            />
            <datalist id='dosage-list'>
              {dosageForm.map((dosage, key) => (
                <option key={key}>{dosage}</option>
              ))}
            </datalist>
          </div>
          <div className='form-group mt-3 col-6'>
            <label>
              Drug Strength <span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              required
              className='form-control'
              value={drugData.strength}
              onChange={handleDrugChange('strength')}
            />
          </div>
          <div className='form-group mt-3 col-4'>
            <label>
              Frequency <span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              list='frequencylist'
              required
              className='form-control'
              value={drugData.frequency}
              onChange={handleDrugChange('frequency')}
            />
            <datalist id='frequencylist'>
              {frequencyList?.map((drugFrequency, key) => (
                <option key={key}>{drugFrequency}</option>
              ))}
            </datalist>
          </div>
          <div className='form-group mt-3 col-8'>
            <label>
              Duration (e.g 4 days e.t.c)
              <span className='text-danger'>*</span>
            </label>
            <div className='row'>
              <div className='col-5'>
                <input
                  type='number'
                  required
                  max={120}
                  min={1}
                  className='form-control'
                  // placeholder="e.g 2/7, 3/12"
                  value={drugDuration.unit}
                  onChange={handleDurationChange('unit')}
                />
              </div>
              <div className='col-7'>
                <select
                  required
                  className='form-select'
                  value={drugDuration.total}
                  onChange={handleDurationChange('total')}
                >
                  <option value='7'>Days</option>
                  <option value='52'>Weeks</option>
                  <option value='12'>Months</option>
                </select>
              </div>
            </div>
          </div>
          <div className='form-group mt-3'>
            <label>
              Instruction<span className='text-danger'>*</span>
            </label>
            <textarea
              className='form-control'
              value={drugData.notes}
              onChange={handleDrugChange('notes')}
            />
          </div>

          <div className='mt-3 d-grid'>
            <div className='row'>
              <div className='col-6 d-grid'>
                <button
                  type='button'
                  className='btn outline-primary-button'
                  onClick={addDrugToList}
                >
                  Add Drug
                </button>
              </div>
              <div className='col-6 d-grid'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={saveAndProceed}
                >
                  Save and Proceed
                </button>
              </div>
            </div>
          </div>
          <br />
        </fieldset>
      </form>
    </section>
  );
};

export default PrescriptionPad;
