import { useAddToHomescreenPrompt } from '../../../hooks/AddToHomescreenPrompt';

const AddToHomeScreen = () => {
  const [promptToInstall] = useAddToHomescreenPrompt();

  return (
    <button
      id='install-btn'
      className='btn btn-primary'
      onClick={promptToInstall}
    >
      Pin Erx to Home
    </button>
  );
};

export default AddToHomeScreen;
