import { useCallback, useContext, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { PrescriptionTabs } from '../../../utils/mappings';
import BasicInfo from '../../components/prescriptions/BasicInfo';
import PrescriptionNote from '../../components/prescriptions/PrescriptionNote';
import PrescriptionPad from '../../components/prescriptions/PrescriptionPad';
import {
  IDrugs,
  initialPrescriptionData,
} from '../../../interfaces/PrescriptionInterface';
import { initialPrescriptionNote } from '../../../interfaces/NoteInterface';
import { addToast } from '../../../utils/toastNotifications';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { initialPatient } from '../../../interfaces/PatientInterface';
import { IAppState } from '../../../interfaces/AppInterfaces';

const PrescriptionNew = ({
  source = 'NonTelemedicine',
}: {
  source: 'Telemedicine' | 'NonTelemedicine';
}) => {
  const history = useHistory();
  const { appState, dispatch }: { appState: IAppState; dispatch: any } =
    useContext(AppContext);

  const [key, setKey] = useState(PrescriptionTabs.patient);
  const [screenStatus, setScreenStatus] = useState({
    basicInfoComplete: false,
    notesComplete: false,
    prescriptionComplete: false,
  });

  const [prescriptionData, setPrescriptionData] = useState(
    initialPrescriptionData,
  );
  const [patientData, setPatientData] = useState(initialPatient);
  const [notesData, setNotesData] = useState(initialPrescriptionNote);

  const handleBasicInfoChange = (input: string) => (event: any) => {
    setPatientData({
      ...patientData,
      [input]: event.target.value,
    });
  };

  const handleChange = (input: string) => (event: any) => {
    setPrescriptionData({
      ...prescriptionData,
      [input]: event.target.value,
    });
  };

  const handleSelectChange = (input: string, value: string) => {
    setPrescriptionData({
      ...prescriptionData,
      [input]: value,
    });
  };

  const handleNotesChange = (input: string) => (event: any) => {
    setNotesData({
      ...notesData,
      [input]: event.target.value,
    });
  };

  /* since this is the last one to call, we add all logic and move to the summary screen  */
  const handleAddDrugs = (drugs: IDrugs[]) => {
    prescriptionData.drugs = drugs;

    notesData.noteType = 'Prescription';
    prescriptionData.consultationType = 'Prescription';
    prescriptionData.consultationNote = notesData;

    if (source === 'Telemedicine') {
      prescriptionData.sendToWefill = true;
    }

    setScreenStatus({
      ...screenStatus,
      prescriptionComplete: true,
    });

    //check if all data filled
    if (!screenStatus.basicInfoComplete) {
      addToast('Please fill the basic information', 'error');
      return;
    }
    if (!screenStatus.notesComplete) {
      addToast('Please fill the notes section', 'error');
      return;
    }
    //save all data to context
    dispatch({
      type: 'SET_PRESCRIPTION_DATA',
      payload: prescriptionData,
    });

    dispatch({
      type: 'SET_PRESCRIPTION_NOTE',
      payload: notesData,
    });

    dispatch({
      type: 'SET_PATIENT_DATA',
      payload: patientData,
    });

    history.push('/prescriptions/summary');
  };

  //Assumption is that the Tab will have enforced form validation before allowing the Continue button
  const navigateToScreen = (tabKey: string, statusKey: string) => {
    setScreenStatus({
      ...screenStatus,
      [statusKey]: true,
    });

    setKey(tabKey);
  };

  const loadStateFromContext = useCallback(() => {
    //check if state exist in context
    if (appState.patientData && appState.patientData !== null) {
      setPatientData(appState.patientData);
    }
    if (appState.prescriptionData && appState.prescriptionData !== null) {
      setPrescriptionData(appState.prescriptionData);
    }
    if (appState.prescriptionNote && appState.prescriptionNote !== null) {
      setNotesData(appState.prescriptionNote);
    }
  }, [
    appState.patientData,
    appState.prescriptionNote,
    appState.prescriptionData,
  ]);

  useEffect(() => {
    loadStateFromContext();
  }, [loadStateFromContext]);

  return (
    <section className='row justify-content-center prescription-summary me-1 ms-1 me-sm-3 ms-sm-3'>
      <fieldset className='col-sm-10 col-md-8 col-lg-5 mt-5'>
        <h5 className='text-center fs-18'>
          Consultation for : {patientData.firstName} {patientData.lastName}
        </h5>
        <Tabs
          fill
          id='controlled-tab-example'
          activeKey={key}
          onSelect={(k) => setKey(k as string)}
          className='prescription-tab'
        >
          <Tab
            eventKey={PrescriptionTabs.patient}
            title={
              <div className=''>
                <img
                  className='img-fluid mt--1'
                  src={require('../../../assets/images/user.svg').default}
                  alt='user'
                />
                <span className='ms-2'>{PrescriptionTabs.patient}</span>
              </div>
            }
          >
            <BasicInfo
              patientData={patientData}
              handleBasicInfoChange={handleBasicInfoChange}
              serviceCharge={prescriptionData.serviceCharge}
              handleChange={handleChange}
              handleNext={() =>
                navigateToScreen(PrescriptionTabs.notes, 'basicInfoComplete')
              }
              selectChange={
                source === 'Telemedicine' ? handleSelectChange : undefined
              }
            />
          </Tab>
          <Tab
            eventKey={PrescriptionTabs.notes}
            title={
              <div className=''>
                <img
                  className='img-fluid mt--1'
                  src={
                    require('../../../assets/images/clipboard-transparent.svg')
                      .default
                  }
                  alt='clipboard'
                />
                <span className='ms-2'>{PrescriptionTabs.notes}</span>
              </div>
            }
          >
            <PrescriptionNote
              notesData={notesData}
              handleChange={handleNotesChange}
              handleNext={() =>
                navigateToScreen(PrescriptionTabs.prescription, 'notesComplete')
              }
            />
          </Tab>
          <Tab
            eventKey={PrescriptionTabs.prescription}
            title={
              <div className=''>
                <img
                  className='img-fluid mt--1'
                  src={
                    require('../../../assets/images/prescription-pad-transparent.svg')
                      .default
                  }
                  alt='prescription pad'
                />
                <span className='ms-2'>{PrescriptionTabs.prescription}</span>
              </div>
            }
          >
            <PrescriptionPad callback={handleAddDrugs} />
          </Tab>
        </Tabs>
      </fieldset>
    </section>
  );
};

export default PrescriptionNew;
