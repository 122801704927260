import { useCallback, useContext, useEffect, useState } from 'react';
import PrescriptionBar from '../../components/prescription_view/PrescriptionBar';
import PrescriptionTable from '../../components/prescription_view/PrescriptionTable';
import { ConsultationTypeMap, PrescriptionTabs } from '../../../utils/mappings';
import { AppContext } from '../../../context/AppContext';
import { Tab, Tabs } from 'react-bootstrap';
import { IPrescriptionResponse } from '../../../interfaces/PrescriptionInterface';
import { IAppState } from '../../../interfaces/AppInterfaces';
import { endpoints } from '../../../utils/URLs';
import axios from 'axios';
import { addToast } from '../../../utils/toastNotifications';

const PrescriptionsViewNew = () => {
  const { appState }: { appState: IAppState; dispatch: any } =
    useContext(AppContext);

  const [prescriptionData, setPrescriptionData] = useState<
    IPrescriptionResponse[]
  >([]);
  const [loading, setLoading] = useState(false);

  const [key, setKey] = useState(PrescriptionTabs.allConsultations);

  //reloads on key change
  const getConsultations = useCallback(
    async (consultationType: string | null) => {
      const practitionerId = appState.practitioner!.practitionerId;

      let url = `${endpoints.consultations.mainUrl}/practitioner/${practitionerId}`;

      if (consultationType !== null) {
        url = `${endpoints.consultations.mainUrl}/practitioner/${practitionerId}?consultationType=${consultationType}`;
      }
      setLoading(true);
      try {
        const response = await axios.get(url);

        setPrescriptionData(response.data);
      } catch (error) {
        addToast('Network error occured', 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [appState.practitioner],
  );

  useEffect(() => {
    const consultationType = ConsultationTypeMap.filter(
      (x) => x.text === key,
    )[0];
    const consultationValue = consultationType ? consultationType.value : null;
    getConsultations(consultationValue);
  }, [key, getConsultations]);

  return (
    <section className='row justify-content-center border prescription-summary p-0 p-sm-5'>
      <Tabs
        fill
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k) => setKey(k as string)}
        className='prescription-tab'
      >
        <Tab
          eventKey={PrescriptionTabs.allConsultations}
          title={
            <div className=''>
              <img
                className='img-fluid mt--1'
                src={
                  require('../../../assets/images/prescription-pad-transparent.svg')
                    .default
                }
                alt='prescription pad'
              />
              <span className='ms-2'>{PrescriptionTabs.allConsultations}</span>
            </div>
          }
        >
          <div className='col-12'>
            <PrescriptionBar
              ctaLink='prescriptions/new'
              ctaText='Write New Prescription'
            />
            <PrescriptionTable
              prescriptionData={prescriptionData}
              isLoading={loading}
            />
          </div>
        </Tab>
        <Tab
          eventKey={PrescriptionTabs.prescription}
          title={
            <div className=''>
              <img
                className='img-fluid mt--1'
                src={
                  require('../../../assets/images/prescription-pad-transparent.svg')
                    .default
                }
                alt='prescription pad'
              />
              <span className='ms-2'>{PrescriptionTabs.prescription}</span>
            </div>
          }
        >
          <div className='col-12'>
            <PrescriptionBar
              ctaLink='prescriptions/new'
              ctaText='Write New Prescription'
            />
            <PrescriptionTable
              prescriptionData={prescriptionData}
              isLoading={loading}
            />
          </div>
        </Tab>
        <Tab
          eventKey={PrescriptionTabs.medicalAdvice}
          title={
            <div className=''>
              <img
                className='img-fluid mt--1'
                src={
                  require('../../../assets/images/note-transparent.svg').default
                }
                alt='prescription pad'
              />
              <span className='ms-2'>{PrescriptionTabs.medicalAdvice}</span>
            </div>
          }
        >
          <div className='col-12'>
            <PrescriptionBar
              ctaLink='prescriptions/medical-advice'
              ctaText='Write Medical Advice'
            />
            <PrescriptionTable
              prescriptionData={prescriptionData}
              isLoading={loading}
            />
          </div>
        </Tab>
        <Tab
          eventKey={PrescriptionTabs.investigationReport}
          title={
            <div className=''>
              <img
                className='img-fluid mt--1'
                src={
                  require('../../../assets/images/clipboard-transparent.svg')
                    .default
                }
                alt='prescription pad'
              />
              <span className='ms-2'>
                {PrescriptionTabs.investigationReport}
              </span>
            </div>
          }
        >
          <div className='col-12'>
            <PrescriptionBar
              ctaLink='prescriptions/investigation-report'
              ctaText='Write New Report'
            />
            <PrescriptionTable
              prescriptionData={prescriptionData}
              isLoading={loading}
            />
          </div>
        </Tab>
      </Tabs>
    </section>
  );
};

export default PrescriptionsViewNew;
