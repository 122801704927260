import { Link } from 'react-router-dom';
import TopDoctor from './TopDoctor';
import { useConsultationReport } from '../../../../hooks/ConsultationReportHook';
import { useContext } from 'react';
import { AppContext } from '../../../../context/AppContext';
import { IAppState } from '../../../../interfaces/AppInterfaces';

const LeaderboardOverview = () => {
  const { consultation } = useConsultationReport();

  const { appState }: { appState: IAppState } = useContext(AppContext);

  return (
    <div
      className='analytics-card1 position-relative'
      style={{ minHeight: '300px' }}
    >
      <div className='analytics-card1-inner'>
        <div>
          <div className='fs-20 fw-600 analytics-card1-header grey-600'>
            Leaderboard
          </div>
          <div className='mt-2 fw-600 fs-32 grey-700 analytics-card1-header'>
            1st
          </div>
          <div className='mt-1 d-flex'>
            <img
              src={
                require(`../../../../assets/images/icon-chart-down.svg`).default
              }
              className='img-fluid'
              alt='chart'
            />
            <div className='fw-500 grey-600 fs-12 text-spacer1'>
              <span className='ms-2'>This month</span>
            </div>
          </div>
        </div>
        <img
          src={require(`../../../../assets/images/trophy.svg`).default}
          className='img-fluid'
          alt='trophy'
        />
      </div>
      <div className='d-flex mt-2'></div>
      <div className='mt-4'>
        <TopDoctor
          consultations={consultation?.consultationMetric.prescriptions!}
          doctorFirstName={`${appState.practitioner?.firstName} `}
          doctorLastName={`${appState.practitioner?.lastName}`}
          doctorImage=''
          position={1}
        />
        {/* <TopDoctor /> */}
      </div>
      <Link
        to='/profile'
        className='mt-4 analytics-card1-inner border-top pt-3 position-absolute mt-5 mt-sm-0'
        style={{ width: '90%', bottom: '6%' }}
      >
        <div className='fs-12 fw-500 primary-7'>Spot me</div>
        <img
          src={require(`../../../../assets/images/arrow-right.svg`).default}
          alt='arrow-right'
          className='img-fluid'
        />
      </Link>
    </div>
  );
};

export default LeaderboardOverview;
