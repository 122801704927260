import axios from 'axios';
import { IAppState, IProps } from '../../../interfaces/AppInterfaces';
import { useState, useContext, useEffect } from 'react';
import { isFormValidated } from '../../../utils/formUtils';
import { endpoints } from '../../../utils/URLs';
import { AppContext } from '../../../context/AppContext';
import ButtonLoader from '../../components/common/ButtonLoader';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const LicenceValidation = (props: IProps): JSX.Element => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [mdcnFolio, setMdcnFolio] = useState('');
  const [showModal, setShowModal] = useState(false);

  const { appState }: { appState: IAppState } = useContext(AppContext);

  const auth = useAuth();

  const handleChange = async (event: any) => {
    //if account number and account number is require length
    setMdcnFolio(event.target.value);
  };

  const register = async (event: any) => {
    if (!isFormValidated('licence-form')) {
      return;
    }

    event.preventDefault();

    setButtonLoading(true);

    //call api
    let payload = appState.registrationData;

    payload!.mdcnfolioNumber = mdcnFolio;

    let url = endpoints.practitioner.mainUrl;
    try {
      await axios.post(url, payload);

      //show modal and redirect after 3 secs timemout
      setShowModal(true);
      window.setTimeout(async function () {
        await auth.signinRedirect();
      }, 3000);
    } catch (error: any) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    //check if we have registration data

    if (!appState.registrationData || appState.registrationData === null) {
      props.history.push('/register');
    }
  }, [props.history, appState]);

  let promptModal = (
    <Modal centered show={showModal} backdrop='static'>
      <Modal.Body className='pb-5 pt-5'>
        <div className='text-center'>
          <span
            style={{ fontSize: '5rem' }}
            className='bi bi-check-circle text-success'
          ></span>
        </div>
        <div className='text-center'>
          <div className='text-success'>Sign up successful!</div>
          <div>You will be redirected login page shortly</div>
          <div className='mt-2'>
            If you are not redirected automatically, please click
            <a
              href='https://auth.wellahealth.com/Identity/Account/Login'
              className='ms-1'
            >
              here
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  return (
    <section className='container register-section'>
      {promptModal}
      <div className='row'>
        <div className='col-lg-6 mt-3'>
          <div className='page-card p-5' style={{ minHeight: '900px' }}>
            <div className='logo'>
              <Link to='/'>
                <img
                  alt='logo'
                  src={require('../../../assets/images/logo.svg').default}
                />
              </Link>
            </div>
            <div className='page-card-header fs-56 fw-700'>
              ERx is only available to medical practitioners...
            </div>
            <div className='page-card-subtitle fs-18 fw-400 mt-3'>
              Our ERx community only allows medical practitioners who are
              registered with the Medical and Dental Council of Nigeria, with
              active licenses.
            </div>
          </div>
        </div>
        <div className='col-lg-6 align-items-middle mt-3'>
          <div className='row justify-content-center'>
            <div className='col-md-8' style={{ padding: '20px 30px' }}>
              <div className='logo'>
                <img
                  alt='logo'
                  src={
                    require('../../../assets/images/certificate.svg').default
                  }
                />
              </div>
              <h1 className='licence-title fw-500 fs-32 mt-3'>
                Lets confirm your Medical Practice License
              </h1>
              <div className='page-subtitle'>Lets get you started</div>
              <form
                id='licence-form'
                className='row justify-content-center mt-5'
              >
                <fieldset disabled={buttonLoading}>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <label>MDCN folio number</label>
                      <span className='text-danger'>*</span>
                      <input
                        type='text'
                        className='form-control'
                        value={mdcnFolio}
                        onChange={handleChange}
                        required
                        placeholder='e.g 1234568'
                        style={{ marginBottom: '30px' }}
                      />
                    </div>
                    <div className='mt-3'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-block btn-primary'
                        onClick={register}
                      >
                        Continue
                        {buttonLoading ? <ButtonLoader /> : ''}
                      </button>
                    </div>
                    <div className='mt-3'>
                      <small className='fs-12'>
                        We will notify you after confirming this number, please
                        ensure it is accurate. ERx is only available to
                        practitioners registered with the MDCN.
                      </small>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LicenceValidation;
