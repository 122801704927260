export interface IPractitionerData extends IPractitionerProfileDTO {
  location: string;
  mdcnfolioNumber: string;
  serviceCharge: number;
  practitionerId: number;
  source: number;
}

export interface IPractitionerProfileDTO {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  eSignatureUrl: string;
}

export const practitionerData: IPractitionerData = {
  firstName: '',
  lastName: '',
  email: '',
  location: '',
  mdcnfolioNumber: '',
  serviceCharge: 0,
  phoneNumber: '',
  practitionerId: 0,
  source: 0,
  eSignatureUrl: '',
};
