import PrescriptionSummaryHeader from '../../components/prescription_summary/PrescriptionSummaryHeader';
import PrescriptionSummaryFooter from '../../components/prescription_summary/PrescriptionSummaryFooter';
import PrescriptionSummaryInfo from '../../components/prescription_summary/PrescriptionSummaryInfo';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { endpoints } from '../../../utils/URLs';
import axios from 'axios';
import { IPrescriptionDetailDTO } from '../../../interfaces/PrescriptionInterface';
import { ConsultationType } from '../../../utils/mappings';
import PrescriptionDrugInfo from '../../components/prescription_summary/PrescriptionDrugInfo';
import { addToast } from '../../../utils/toastNotifications';
import NotFound from '../../components/common/NotFound';
import Loader from '../../components/common/Loader';

const PrescriptionDetail = ({
  viewType,
  sendSms,
}: {
  viewType: 'Patient';
  sendSms: boolean;
}) => {
  const [prescriptionData, setData] = useState<
    IPrescriptionDetailDTO | undefined
  >();

  const [loading, setLoading] = useState(false);
  let { code }: any = useParams();
  const history = useHistory();

  //get prescription using the code
  const getPrescription = useCallback(async () => {
    if (
      (code === null || typeof code === 'undefined') &&
      viewType !== 'Patient'
    ) {
      history.push('/prescriptions');
    }

    let consultationCode = code;

    let url = `${endpoints.consultations.mainUrl}/${consultationCode}/details`;

    //logic to allow for patient view
    if (viewType === 'Patient') {
      if (code === null || typeof code === 'undefined') {
        const searchParam = new URLSearchParams(window.location.search);
        consultationCode = searchParam.get('code');
      }
      url = `${endpoints.patients.mainUrl}/consultations/${consultationCode}/details?sendsms=${sendSms}`;
    }

    setLoading(true);
    try {
      const response = await axios.get(url);
      setData(response.data);
    } catch (error: any) {
      if (error.response) {
        //if 422, means no payment made for consultation. if viewtype is type patient-
        if (error.response.status === 422 && viewType === 'Patient') {
          history.push(`/prescriptions/pay?code=${consultationCode}`);
        }
      } else {
        addToast('Network error occured', 'error');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [history, code, viewType, sendSms]);

  const copyLink = () => {
    const prescriptionLink = `${window.location.host}/prescription/pay?code=${code}`;
    navigator.clipboard
      .writeText(prescriptionLink)
      .then(() => {
        addToast('Prescription link copied');
      })
      .catch((err) => {
        console.error('Async: Could not copy text: ', err);
      });
  };

  const getSummaryHeader = (consultationType: string) => {
    switch (consultationType) {
      case ConsultationType.prescription:
        return 'Diagnosis';
      case ConsultationType.medicalAdvice:
        return 'Recommendation';
      default:
        return 'Analysis';
    }
  };

  useEffect(() => {
    getPrescription();
  }, [getPrescription]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {!prescriptionData && <NotFound prescriptionCode={code} />}
          {prescriptionData && (
            <section className='row justify-content-center border prescription-summary p-0 p-sm-5'>
              <div className='col-lg-6 border mb-4'>
                <PrescriptionSummaryHeader
                  patientData={prescriptionData.patient!}
                />
                <PrescriptionSummaryInfo
                  patientData={prescriptionData.patient!}
                  noteType={prescriptionData.consultationType!}
                />
                <div className='mt-4'>
                  <div className='fw-600'>
                    {getSummaryHeader(prescriptionData.consultationType!)}
                  </div>
                  {prescriptionData.consultationNote.length > 0 ? (
                    <div className=''>
                      {prescriptionData.consultationType ===
                      ConsultationType.prescription
                        ? prescriptionData.consultationNote![0].complaint
                        : prescriptionData.consultationNote![0].medicalReport}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {prescriptionData.drugs.length! > 0
                  ? prescriptionData.drugs.map((drug) => (
                      <PrescriptionDrugInfo drug={drug} />
                    ))
                  : ''}
                <PrescriptionSummaryFooter
                  practitionerData={prescriptionData.practitioner!}
                />
                {viewType !== 'Patient' ? (
                  <div className='mt-5 mb-3'>
                    <div className='tile-card border-primary py-0 ps-3 pe-2 py-2 rounded-2 d-flex justify-content-between align-items-center'>
                      <div style={{ minWidth: 0 }}>
                        <div className='prescription-link-text'>
                          <img
                            src={
                              require(`../../../assets/images/link.svg`).default
                            }
                            className='img-fluid me-2'
                            alt='link'
                            width={28}
                          />
                          {window.location.host}/prescriptions/pay?code={code}
                        </div>
                      </div>
                      <div>
                        <button
                          className='btn btn-sm btn-primary rounded-1'
                          onClick={copyLink}
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                    <small>Copy and share the prescription code</small>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </section>
          )}
        </div>
      )}
    </div>
  );
};

export default PrescriptionDetail;
