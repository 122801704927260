export interface IPatientNote {
  patientFirstName: string;
  patientLastName: string;
  patientPhoneNumber: string;
  noteContent: string;
  patientEmail: string;
  dateSaved: string;
}

export interface IPrescriptionNote {
  complaint: string;
  medicalHistory: string;
  medicalReport: string;
  //fields specific for prescription note
  diagnosis: string;
  managementPlan: string;
  nextAppointment: string;
  noteType: 'Prescription' | 'MedicalAdvice' | 'InvestigationReport';
}

export const initialPrescriptionNote: IPrescriptionNote = {
  complaint: '',
  medicalHistory: '',
  medicalReport: '',

  //fields specific for prescription note
  diagnosis: '',
  managementPlan: '',
  nextAppointment: '',
  noteType: 'Prescription',
};
