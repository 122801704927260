export interface IWalletTransactionHistory {
  transactionDate: string;
  transactions: Array<IWalletTransaction>;
}

export interface IWalletTransaction {
  transactionType: string;
  transactionStatus: string;
  amount: number;
  transactionDate: string;
  transactionName: string;
}

export interface IWalletAccountSummary {
  walletBalance: number;
  totalDebits: number;
  totalCredits: number;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
}

export interface IWalletAccountDTO {
  practitionerId: number;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  bankCode: string;
}

export interface IBankList {
  id: number;
  name: string;
  code: string;
}

export const initialBank: IBankList = {
  id: 0,
  name: '--Select a bank --',
  code: '',
};

export const walletData: IWalletAccountSummary = {
  bankAccountName: '',
  bankAccountNumber: '',
  bankName: '',
  totalCredits: 0.0,
  totalDebits: 0.0,
  walletBalance: 0.0,
};
