import PrescriptionSummaryHeader from '../../components/prescription_summary/PrescriptionSummaryHeader';
import PrescriptionSummaryFooter from '../../components/prescription_summary/PrescriptionSummaryFooter';
import PrescriptionSummaryInfo from '../../components/prescription_summary/PrescriptionSummaryInfo';
import PrescriptionSummaryAction from '../../components/prescription_summary/PrescriptionSummaryAction';
import { IAppState } from '../../../interfaces/AppInterfaces';
import { useContext, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import PrescriptionDrugInfo from '../../components/prescription_summary/PrescriptionDrugInfo';
import { IPrescriptionSummaryOptions } from '../../../interfaces/PrescriptionInterface';
import axios from 'axios';
import { endpoints } from '../../../utils/URLs';
import { addToast } from '../../../utils/toastNotifications';
import { useHistory } from 'react-router-dom';
import ScreenLoading from '../../components/common/ScreenLoading';

const PrescriptionSummary = () => {
  const history = useHistory();

  const { appState, dispatch }: { appState: IAppState; dispatch: any } =
    useContext(AppContext);
  const [summaryOptions, setSummaryOptions] =
    useState<IPrescriptionSummaryOptions>({
      sendViaSms: true,
      sendViaWhatsapp: false,
    });

  const [loading, setLoading] = useState(false);

  //show error  and go back buttton is appState is empty.
  const sendPrescription = async () => {
    const prescription = appState.prescriptionData;

    prescription!.diagnosis = appState.prescriptionNote?.diagnosis!;
    prescription!.nextAppointment = appState.prescriptionNote?.nextAppointment!;
    prescription!.consultationNote = appState.prescriptionNote;
    prescription!.patient = appState.patientData!;
    prescription!.practitionerId = appState.practitioner?.practitionerId!;
    prescription!.nextAppointment = undefined;

    const url = `${endpoints.consultations.mainUrl}`;
    setLoading(true);
    try {
      await axios.post(url, prescription);
      addToast('Prescription sent successfully', 'success');

      if (summaryOptions.sendViaWhatsapp) {
        //open whatsapp
      }
      resetContextState();
      //push to prescriptions page
      history.push('/prescriptions');
    } catch (error) {
      addToast('An error occured', 'error');
    } finally {
      setLoading(false);
    }
  };

  const resetContextState = () => {
    dispatch({
      type: 'SET_PRESCRIPTION_DATA',
      payload: undefined,
    });

    dispatch({
      type: 'SET_PRESCRIPTION_NOTE',
      payload: undefined,
    });

    dispatch({
      type: 'SET_PATIENT_DATA',
      payload: undefined,
    });
  };

  const editPrescription = () => {
    //go back to prescription page
    history.push('/prescriptions/new');
  };

  const handleChange = (input: string, value: boolean) => {
    setSummaryOptions({
      ...summaryOptions,
      [input]: value,
    });
  };

  return (
    <div>
      {loading ? <ScreenLoading /> : ''}

      {(typeof appState === 'undefined' ||
        typeof !appState.prescriptionData === 'undefined') && (
        <div>No prescription data</div>
      )}
      {appState && appState.prescriptionData && (
        <section className='row justify-content-center border prescription-summary p-0 p-sm-5'>
          <div className='col-lg-6 border mb-4'>
            <PrescriptionSummaryHeader patientData={appState.patientData!} />
            <PrescriptionSummaryInfo
              patientData={appState.patientData!}
              noteType={appState.prescriptionNote?.noteType!}
            />
            <div className='mt-4'>
              <div className='fw-600'>Diagnosis</div>
              <div className=''>{appState.prescriptionNote?.complaint}</div>
            </div>

            {appState.prescriptionData?.drugs.length! > 0
              ? appState.prescriptionData?.drugs.map((drug) => (
                  <PrescriptionDrugInfo drug={drug} />
                ))
              : ''}
            <PrescriptionSummaryFooter
              practitionerData={appState.practitioner!}
            />
          </div>
          <PrescriptionSummaryAction
            sendPrescription={sendPrescription}
            editPrescription={editPrescription}
            handleChange={handleChange}
            options={summaryOptions}
          />
        </section>
      )}
    </div>
  );
};

export default PrescriptionSummary;
