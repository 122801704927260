import { FormEvent, useId } from 'react';
import { IPrescriptionNote } from '../../../interfaces/NoteInterface';
import { isFormValidated } from '../../../utils/formUtils';

const MedicalReportEditor = ({
  notesData,
  handleChange,
  handleNext,
}: {
  notesData: IPrescriptionNote;
  handleChange: Function;
  handleNext: Function;
}) => {
  const formId = useId();

  const proceedToNextPage = (event: FormEvent) => {
    //check if form is valid
    const formName = `report-editor-${formId}`;
    if (!isFormValidated(formName)) {
      return;
    }

    event.preventDefault();

    handleNext();
  };
  return (
    <section className='mt-5'>
      <form id={`report-editor-${formId}`}>
        <fieldset className='row'>
          <div className='form-group mt-3'>
            <label>
              Report <span className='text-danger'>*</span>
            </label>
            <textarea
              rows={15}
              className='form-control'
              required
              value={notesData.medicalReport}
              onChange={handleChange('medicalReport')}
            />
          </div>

          <div className='mt-5 pt-5 d-grid'>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={proceedToNextPage}
            >
              Save and Proceed
            </button>
          </div>
          <br />
        </fieldset>
      </form>
    </section>
  );
};

export default MedicalReportEditor;
