import { IWalletTransaction } from '../../../../interfaces/WalletInterface';
import { formatLongDateTime } from '../../../../utils/dateTimeUtils';
import { NairaSymbol } from '../../../../utils/mappings';

const RecentTransaction = (walletSummary: IWalletTransaction) => {
  return (
    <div className='d-flex analytics-card1-inner align-items-start mt-3'>
      <div className='d-flex align-items-center'>
        <img
          src={require(`../../../../assets/images/debit-arrow-up.svg`).default}
          className='img-fluid'
          alt='arrow up'
        />
        <div className='ms-3'>
          <div className='grey-700 f-16 fw-600'>
            {walletSummary.transactionName}
          </div>
          <div className='grey-600 fs-12 fw-400'>
            {formatLongDateTime(walletSummary.transactionDate)}
            {/* 23 Nov, 2023 . 4:15pm */}
          </div>
        </div>
      </div>
      <div>
        {NairaSymbol}
        {walletSummary.amount.toLocaleString()}
      </div>
    </div>
  );
};

export default RecentTransaction;
