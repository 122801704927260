import useSWR from 'swr';
import {
  IWalletReportSummary,
  initialWalletSummary,
} from '../interfaces/ReportInterface';
import { endpoints } from '../utils/URLs';
import fetcher from '../utils/axiosFetcher';

export const useWalletReport = () => {
  const { data, error } = useSWR<IWalletReportSummary, any>(
    endpoints.reports.walletReport,
    fetcher,
    {
      fallbackData: initialWalletSummary,
      errorRetryCount: 1,
      revalidateOnFocus: false,
    },
  );

  return {
    walletSummary: data,
    error: error,
  };
};
