import ConsultationOverview from '../consultation_overview/ConsultationOverview';
import WalletTransactionsOverview from '../wallet_overview/WalletTransactionsOverview';
import LeaderboardOverview from '../leader_overview/LeaderboardOverview';
import IncomeOverviewCard from './IncomeOverviewCard';
import PatientsOverviewCard from './PatientsOverviewCard';

const AnalyticsOverview = () => {
  return (
    <section className='mt-5'>
      <div className='row'>
        <div className='col-lg-4 col-md-6 mt-3'>
          <PatientsOverviewCard />
        </div>
        <div className='col-lg-4 col-md-6 mt-3'>
          <IncomeOverviewCard />
        </div>
        <div className='col-lg-4 col-md-6 mt-3'>
          <img
            src={require('../../../../assets/images/ad.png')}
            className='img-fluid ad-height'
            alt='meditrina'
          />
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-lg-4 col-md-6 mt-3'>
          <ConsultationOverview />
        </div>
        <div className='col-lg-4 col-md-6 mt-3'>
          <WalletTransactionsOverview />
        </div>
        <div className='col-lg-4 col-md-6 mt-3'>
          <LeaderboardOverview />
        </div>
      </div>
    </section>
  );
};

export default AnalyticsOverview;
