import { IDrugs } from '../../../interfaces/PrescriptionInterface';

const PrescriptionDrugInfo = ({ drug }: { drug: IDrugs }) => {
  return (
    <div className='mt-4 border-bottom pb-1'>
      <div className='fw-600'>{drug.name}</div>
      <div className=''>
        {drug.dose}; {drug.strength}; {drug.frequency}; {drug.duration}
      </div>
      {drug.notes !== '' ? (
        <div>
          <div className='fw-600 mt-2'>Instruction</div>
          <div>{drug.notes}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PrescriptionDrugInfo;
