const NotFound = ({ prescriptionCode }: any) => {
  return (
    <div className='text-center'>
      <span className='bi-exclamation-triangle-fill exclamation-icon text-danger'></span>
      <div className='exclamation-desc mt-3'>
        The prescription code <strong>{prescriptionCode}</strong> is invalid
      </div>
    </div>
  );
};

export default NotFound;
