import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  ToastProvider,
  DefaultToastContainer,
} from 'react-toast-notifications';
import { AppProvider } from './context/AppContext';
import 'bootstrap/dist/css/bootstrap.min.css';
// components

//stlyes
import './app/styles.css';

import { AuthProvider } from 'react-oidc-context';

import Dashboard from './app/pages/dashboard/Dashboard';
import Callback from './app/pages/auth/Callback';
import RegisterForm from './app/pages/auth/Register';
import LicenceValidation from './app/pages/auth/LicenceValidation';
import PatientPrescriptions from './app/pages/patient/PatientPrescriptions';
import PaymentSuccess from './app/pages/patient/PaymentSuccess';
import PatientDrugs from './app/pages/patient/PatientDrugs';
import Home from './app/Home';
import PaymentVerification from './app/pages/admin/PaymentVerification';
import ViewEditProfile from './app/pages/practitioner/ViewEditProfile';
import Layout from './app/components/dashboard/Layout';
import PrescriptionNew from './app/pages/prescriptions/PrescriptionNew';
import NewTelePrescription from './app/pages/prescriptions/NewTelePrescription';
import RefreshToast from './app/components/common/RefreshToast';
import ScrollToTop from './app/components/common/ScrollToTop';
import WithdrawalPage from './app/pages/wallets/WithdrawalPage';
import AddNotes from './app/pages/notes/AddNotes';
import TelemedicineRequests from './app/pages/requests/TelemedicineRequests';
import WalletDashboard from './app/pages/wallets/WalletDashboard';
import MedicalAdviceNew from './app/pages/prescriptions/MedicalAdviceNew';
import InvestigationReportNew from './app/pages/prescriptions/InvestigationReportNew';
import PrescriptionSummary from './app/pages/prescriptions/PrescriptionSummary';
import MedicalAdviceSummary from './app/pages/prescriptions/MedicalAdviceSummary';
import PrescriptionsViewNew from './app/pages/prescriptions/PrescriptionsViewNew';
import PrescriptionDetail from './app/pages/prescriptions/PrescriptionDetail';
import Patients from './app/pages/patient/Patients';

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENTID,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL,
  response_type: 'code',
  scope: 'openid profile email roles',
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_POSTLOGOUT_URL,
  filterProtocolClaims: true,
  //userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_OIDC_RENEWAL_URL,
  revokeAccessTokenOnSignout: true,
};

// //adds the context to window so we can access anywhere
declare global {
  interface Window {
    __react_toast_provider: any;
  }
}

window.__react_toast_provider = React.createRef();
// create a default container so we can override the styles
const ToastContainer = (props: any) => (
  <DefaultToastContainer style={{ zIndex: '1900' }} {...props} />
);

//hack to reset background color :)

const App = (): JSX.Element => {
  return (
    <AuthProvider {...oidcConfig}>
      <ToastProvider
        components={{ ToastContainer }}
        ref={window.__react_toast_provider}
      >
        <AppProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path='/signin-callback' component={Callback} />
              {/* <Route component={LoginForm} exact path='/login' /> */}
              <Route component={RegisterForm} exact path='/register' />
              <Route
                component={LicenceValidation}
                exact
                path='/register/licence'
              />
              <Route
                component={PatientPrescriptions}
                exact
                path='/prescriptions/pay'
              />
              <Route
                component={PaymentSuccess}
                exact
                path='/prescriptions/payment/success'
              />
              <Route exact path='/' component={Home} />
              <Route
                component={PatientDrugs}
                exact
                path='/prescriptions/drugs'
              />
              <Layout exact path='/dashboard' component={Dashboard} />
              <Layout
                exact
                path='/payments/verify'
                component={PaymentVerification}
              />
              <Layout exact path='/profile' component={ViewEditProfile} />
              <Layout
                exact
                path='/prescriptions'
                component={PrescriptionsViewNew}
              />

              <Layout
                exact
                path='/prescriptions/new'
                component={PrescriptionNew}
              />
              <Layout
                exact
                path='/prescriptions/medical-advice'
                component={MedicalAdviceNew}
              />
              <Layout
                exact
                path='/prescriptions/investigation-report'
                component={InvestigationReportNew}
              />
              <Layout
                exact
                path='/prescriptions/summary'
                component={PrescriptionSummary}
              />
              <Layout
                exact
                path='/prescriptions/medical-advice/summary'
                component={MedicalAdviceSummary}
              />
              <Layout
                exact
                path='/prescriptions/investigation-report/summary'
                component={MedicalAdviceSummary}
              />
              <Layout
                exact
                path='/prescriptions/new/telemedicine'
                component={NewTelePrescription}
              />

              <Layout exact path='/patients' component={Patients} />
              {/* <Layout
                exact
                path='/prescriptions/edit/:code?'
                component={EditPrescription}
              /> */}
              <Layout
                exact
                path='/prescriptions/view/:code?'
                component={PrescriptionDetail}
              />
              <Layout exact path='/wallets' component={WalletDashboard} />
              <Layout
                exact
                path='/wallets/withdrawal'
                component={WithdrawalPage}
              />
              <Layout exact path='/notes/add' component={AddNotes} />
              <Layout exact path='/requests' component={TelemedicineRequests} />
            </Switch>
            <RefreshToast />
            <ScrollToTop />
          </BrowserRouter>
        </AppProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default App;
