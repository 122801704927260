import { IProps } from '../../../interfaces/AppInterfaces';
import HeaderLogo from '../../components/dashboard/HeaderLogo';

const PaymentSuccess = (props: IProps): JSX.Element => {
  return (
    <div>
      <HeaderLogo />
      <section
        className='container'
        style={{ marginTop: '70px', marginBottom: '50px' }}
      >
        <div className='row justify-content-center'>
          <div className='col-md-6 col-sm-8 center-section'>
            <div className='text-center'>
              <i className='bi-check-circle text-success fs-60'></i>
              <div className='exclamation-desc mt-3'>
                Payment successful. We are verifying your payment and you will
                receive a SMS shortly containing the prescription
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaymentSuccess;
