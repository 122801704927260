import { FormEvent, useState, useEffect, useContext, useCallback } from 'react';
import {
  IPractitionerData,
  practitionerData,
} from '../../../interfaces/PractitionerInterface';
import { IProps } from '../../../interfaces/AppInterfaces';
import axios from 'axios';
import { endpoints } from '../../../utils/URLs';
import { AppContext } from '../../../context/AppContext';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';
import ButtonLoader from '../../components/common/ButtonLoader';

const ViewProfile = (props: IProps): JSX.Element => {
  const [profileData, setProfileData] =
    useState<IPractitionerData>(practitionerData);
  const { dispatch } = useContext(AppContext);
  const [isEdit, setIsEdit] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const editProfile = (event: FormEvent) => {
    event.preventDefault();
    setIsEdit(true);
  };

  const handleChange = (input: string) => (event: any) => {
    setProfileData({
      ...profileData,
      [input]: event.target.value,
    });
  };

  const loadProfile = useCallback(async () => {
    // const phoneNumber = localStorage.getItem('phone');

    // if (phoneNumber === null) {
    //   window.location.href = '/';
    // }

    const url = `${endpoints.practitioner.mainUrl}`;

    try {
      const response = await axios.get(url);
      setProfileData(response.data);

      dispatch({
        type: 'UPDATE_PROFILE',
        payload: response.data,
      });
    } catch (error) {
      addToast('Network error occured', 'error');
      console.error(error);
    }
  }, [dispatch]);

  const updateProfile = async (event: FormEvent) => {
    //validate form
    if (!isFormValidated('edit-profile-form')) {
      return;
    }
    event.preventDefault();

    setButtonLoading(true);
    const url = endpoints.practitioner.mainUrl;

    try {
      await axios.put(url, profileData);
      setIsEdit(false);
      dispatch({
        type: 'UPDATE_PROFILE',
        payload: profileData,
      });
      addToast('Profile updated successfully', 'success');
    } catch (error) {
      console.error(error);
      addToast('Network error occured', 'error');
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  return (
    <div>
      <h5 className='text-center'>Profile information </h5>
      <div className='row justify-content-center'>
        <div
          className='col-sm-6 col-md-6'
          style={{
            marginTop: '30px',
            backgroundColor: '#fff',
            padding: '20px',
          }}
        >
          <form id='edit-profile-form'>
            {isEdit ? (
              ''
            ) : (
              <div className='text-right'>
                <button className='btn' onClick={editProfile}>
                  <i className='bi-pencil-square'></i>
                  Edit Profile
                </button>
              </div>
            )}

            <fieldset disabled={!isEdit}>
              <div className='form-group mt-3'>
                <label>First Name</label>
                <input
                  type='text'
                  className='form-control'
                  required
                  value={profileData.firstName}
                  onChange={handleChange('firstName')}
                />
              </div>
              <div className='form-group mt-3'>
                <label>Last Name</label>
                <input
                  type='text'
                  className='form-control'
                  required
                  value={profileData.lastName}
                  onChange={handleChange('lastName')}
                />
              </div>
              <div className='form-group mt-3'>
                <label>Email</label>
                <input
                  required
                  type='email'
                  className='form-control'
                  value={profileData.email}
                  onChange={handleChange('email')}
                />
              </div>
              <div className='form-group mt-3'>
                <label>MDCN Folio Number</label>
                <input
                  required
                  type='text'
                  className='form-control'
                  value={profileData.mdcnfolioNumber}
                  onChange={handleChange('mdcnfolioNumber')}
                />
              </div>
              <div className='form-group mt-3'>
                <label>Location</label>
                <textarea
                  className='form-control'
                  required
                  value={profileData.location}
                  onChange={handleChange('location')}
                />
              </div>
              <div className='form-group mt-3'>
                <label>Fees</label>
                <input
                  required
                  type='number'
                  className='form-control'
                  value={profileData.serviceCharge}
                  onChange={handleChange('serviceCharge')}
                  min='10'
                  max='10000'
                />
              </div>
              {isEdit ? (
                <div className='mt-3'>
                  <button
                    type='submit'
                    className='btn btn-block btn-primary'
                    onClick={updateProfile}
                  >
                    Save
                    {buttonLoading ? <ButtonLoader /> : ''}
                  </button>
                </div>
              ) : (
                ''
              )}
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
