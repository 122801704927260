import { IProps } from '../../../interfaces/AppInterfaces';
import { useState, useEffect } from 'react';
import HeaderLogo from '../../components/dashboard/HeaderLogo';
import PrescriptionDetail from '../prescriptions/PrescriptionDetail';

const PatientDrugs = (props: IProps): JSX.Element => {
  const [sendSms, setSendSms] = useState(false);

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    const sendSmsParam = searchParam.get('sendsms') as string;
    if (sendSmsParam !== null && typeof sendSmsParam !== 'undefined') {
      const sendSmsBool = sendSmsParam === 'true' ? true : false;
      setSendSms(sendSmsBool);
    }
  }, []);

  return (
    <div>
      <HeaderLogo />
      <section
        className='container'
        style={{ marginTop: '70px', marginBottom: '50px' }}
      >
        <div className='row justify-content-center'>
          <div className='col-lg-12 center-section'>
            <PrescriptionDetail viewType='Patient' sendSms={sendSms} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default PatientDrugs;
