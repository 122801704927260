import { IPatientData } from '../../../interfaces/PatientInterface';
import { ConsultationTypeMap } from '../../../utils/mappings';

const PrescriptionSummaryInfo = ({
  patientData,
  noteType,
}: {
  patientData?: IPatientData;
  noteType: string;
}) => {
  return (
    <div className='mt-4'>
      <h5 className='text-center primary-7 fw-500'>
        {ConsultationTypeMap.find((x) => x.value === noteType)?.text}
      </h5>
      <div className='d-flex mt-4'>
        <div className='fw-600'>
          Name:
          <span className='fw-400 ms-1'>
            {patientData?.firstName} {patientData?.lastName}
          </span>
        </div>
        <div className='ms-4 fw-600'>
          Gender:
          <span className='fw-400 ms-1'>{patientData?.gender}</span>
        </div>
        <div className='ms-4 fw-600'>
          Age:
          <span className='fw-400 ms-1'>
            {patientData?.dateOfBirth !== null
              ? new Date().getFullYear() -
                new Date(patientData?.dateOfBirth!).getFullYear()
              : 'NIL'}
          </span>
        </div>
      </div>
      <div className='d-flex gap-2 mt-1'>
        <div className='fw-600'>
          Address:
          <span className='fw-400 ms-1'>{patientData?.location}</span>
        </div>
      </div>
      <div className='d-flex gap-2 mt-1'>
        <div className='fw-600'>
          Consultation Date:
          <span className='fw-400 ms-1'>{new Date().toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionSummaryInfo;
