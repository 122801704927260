import { useState } from 'react';
import { IPatientNote } from '../../../interfaces/NoteInterface';
import { IProps } from '../../../interfaces/AppInterfaces';
import axios from 'axios';
import { endpoints } from '../../../utils/URLs';
import { isFormValidated } from '../../../utils/formUtils';
import { addToast } from '../../../utils/toastNotifications';

const AddNote = (props: IProps): JSX.Element => {
  const initialData: IPatientNote = {
    patientFirstName: '',
    patientLastName: '',
    patientEmail: '',
    dateSaved: '',
    patientPhoneNumber: '',
    noteContent: '',
  };

  const [notesData, setNotesData] = useState(initialData);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleChange = (input: string) => (event: any) => {
    setNotesData({
      ...notesData,
      [input]: event.target.value,
    });
  };

  const saveNote = async (event: any) => {
    if (!isFormValidated('notes-form')) {
      return;
    }

    event.preventDefault();
    const url = endpoints.notes.mainUrl;
    setButtonLoading(true);
    try {
      await axios.post(url, notesData);
      addToast('Note saved successfully', 'success');
      setNotesData(initialData);
    } catch (error) {
      addToast('An error has occured', 'error');
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <div>
      <h5 className='text-center'>Add Notes </h5>
      <div className='row justify-content-center'>
        <div
          className='col-sm-6 col-md-6'
          style={{
            marginTop: '30px',
            backgroundColor: '#fff',
            padding: '20px',
          }}
        >
          <form id='notes-form'>
            <fieldset disabled={buttonLoading}>
              <div className='form-group'>
                <label>First Name</label>
                <input
                  type='text'
                  className='form-control'
                  required
                  value={notesData.patientFirstName}
                  onChange={handleChange('patientFirstName')}
                />
              </div>
              <div className='form-group'>
                <label>Last Name</label>
                <input
                  type='text'
                  className='form-control'
                  required
                  value={notesData.patientLastName}
                  onChange={handleChange('patientLastName')}
                />
              </div>
              <div className='form-group'>
                <label>Email</label>
                <input
                  type='email'
                  className='form-control'
                  value={notesData.patientEmail}
                  onChange={handleChange('patientEmail')}
                />
              </div>

              <div className='form-group'>
                <label>Phone Number</label>
                <input
                  required
                  type='text    '
                  className='form-control'
                  value={notesData.patientPhoneNumber}
                  onChange={handleChange('patientPhoneNumber')}
                />
              </div>

              <div className='form-group'>
                <label>Notes</label>
                <textarea
                  className='form-control'
                  required
                  value={notesData.noteContent}
                  onChange={handleChange('noteContent')}
                />
              </div>

              <div>
                <button
                  type='submit'
                  className='btn btn-block request-btn-white'
                  onClick={saveNote}
                  onSubmit={saveNote}
                >
                  Save
                  {buttonLoading ? (
                    <span
                      className='spinner-border spinner-border-sm btn-spinner'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  ) : (
                    ''
                  )}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNote;
