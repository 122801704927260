import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const PageLoadingProgressBar = () => {
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    //scroll to top on page changes
    const topElement = document.getElementById('scroll-here') as HTMLElement;
    topElement.scrollIntoView();

    if (
      history.location.pathname.includes('dashboard') ||
      history.location.pathname.includes('/')
    ) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
    return history.listen((location: any, action: any) => {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 5000);
    });
  }, [setLoading, history]);

  return (
    <div>
      {isLoading ? (
        <div className='progress progress-custom' style={{ height: '3px' }}>
          <div
            className='progress-bar progress-bar-custom'
            role='progressbar'
            aria-valuenow={0}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default PageLoadingProgressBar;
