import { FormEvent, useId } from 'react';
import { IPrescriptionNote } from '../../../interfaces/NoteInterface';
import { isFormValidated } from '../../../utils/formUtils';

const PrescriptionNote = ({
  notesData,
  handleChange,
  handleNext,
}: {
  notesData: IPrescriptionNote;
  handleChange: Function;
  handleNext: Function;
}) => {
  const formId = useId();

  const proceedToNextPage = (event: FormEvent) => {
    //check if form is valid
    const formName = `prescription-note-${formId}`;
    if (!isFormValidated(formName)) {
      return;
    }
    event.preventDefault();

    handleNext();
  };

  return (
    <section className='mt-5'>
      <form id={`prescription-note-${formId}`}>
        <fieldset className='row'>
          <div className='form-group mt-3'>
            <label>
              Presenting complaint <span className='text-danger'>*</span>
            </label>
            <textarea
              rows={3}
              className='form-control'
              required
              value={notesData.complaint}
              onChange={handleChange('complaint')}
            />
          </div>
          <div className='form-group mt-3'>
            <label>
              Diagnosis <span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              className='form-control'
              required
              value={notesData.diagnosis}
              onChange={handleChange('diagnosis')}
            />
          </div>
          <div className='form-group mt-3'>
            <label>
              Management Plan <span className='text-danger'>*</span>
            </label>
            <textarea
              rows={3}
              className='form-control'
              required
              value={notesData.managementPlan}
              onChange={handleChange('managementPlan')}
            />
          </div>
          <div className='form-group mt-3'>
            <label>Follow up appointment</label>
            <input
              type='date'
              className='form-control'
              value={notesData.nextAppointment}
              onChange={handleChange('nextAppointment')}
            />
          </div>

          <br />

          <div className='mt-5 pt-5 d-grid'>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={proceedToNextPage}
            >
              Proceed
            </button>
          </div>
          <br />
        </fieldset>
      </form>
    </section>
  );
};

export default PrescriptionNote;
