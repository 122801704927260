import { Link } from 'react-router-dom';

const Breadcrumbs = ({ crumbs }: any) => {
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div className='mb-4 bg-gray-300' style={{ fontSize: '0.9rem' }}>
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(
        ({ name, path }: { name: string; path: string }, key: number) =>
          key + 1 === crumbs.length ? (
            <span key={key} className='bold bread-space'>
              {name}
            </span>
          ) : (
            <span key={key}>
              <Link className='underline text-blue-500 bread-space' to={path}>
                {name}
              </Link>
              {'>>'}
            </span>
          ),
      )}
    </div>
  );
};

export default Breadcrumbs;
