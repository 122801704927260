let BASE_URL = process.env.REACT_APP_BASE_URL;
let AUTH_URL = process.env.REACT_APP_BASE_AUTH_URL;
let EXT_URL = process.env.REACT_APP_BASE_EXT_URL;

let endpoints = {
  Auth: {
    getLoginOtp: AUTH_URL + '/auth/erx/login/authenticate',
    verifyOtp: AUTH_URL + '/auth/erx/verify',
    getRegisterOtp: AUTH_URL + '/auth/erx/register/authenticate',
  },
  practitioner: {
    mainUrl: BASE_URL + '/erx/practitioners',
  },
  consultations: {
    mainUrl: BASE_URL + '/erx/consultations',
    getDetailsByCode: BASE_URL + '/erx/consultations/details',
  },
  patients: {
    mainUrl: BASE_URL + '/erx/patients',
  },
  payments: {
    mainUrl: BASE_URL + '/erx/payments',
  },
  email: {
    mainUrl: EXT_URL + '/api/orders/sendemail',
  },
  drugs: {
    mainUrl: BASE_URL + '/erx/drugs',
  },
  practitionerWallet: {
    mainUrl: BASE_URL + '/erx/practitioners/wallet', // +/{practitionerId}/wallet and +/{practitionerId}/transactions
  },
  notes: {
    mainUrl: BASE_URL + '/erx/notes', // +/{practitionerId}/wallet and +/{practitionerId}/transactions
  },
  wallets: {
    bankList: BASE_URL + '/wallets/banks/list',
    resolveAccount: BASE_URL + '/wallets/bank/resolve',
  },
  telemedicine: {
    mainUrl: BASE_URL + '/erx/telemedicineRequests',
  },
  website: {
    realTime: EXT_URL + '/hubs/notify',
  },
  pharmacy: {
    mainUrl: BASE_URL + '/partner/pharmacy',
  },
  priceList: {
    mainUrl: BASE_URL + '/erx/pricelist',
  },
  reports: {
    mainUrl: BASE_URL + '/erx/reports',
    walletReport: BASE_URL + '/erx/reports/wallet',
    prescriptionReport: BASE_URL + '/erx/reports/consultations',
    patientReport: BASE_URL + '/erx/reports/patients',
  },
};

//removed modules.exports for Typescript due to --isolatedModules error
export { endpoints, BASE_URL };
