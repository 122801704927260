import { useCallback, useContext, useEffect, useState } from 'react';
import { PrescriptionTabs } from '../../../utils/mappings';
import { Tab, Tabs } from 'react-bootstrap';
import BasicInfo from '../../components/prescriptions/BasicInfo';
import MedicalReportEditor from '../../components/prescriptions/MedicalReportEditor';
import MedicalInfoNote from '../../components/prescriptions/MedicalInfoNote';
import { initialPrescriptionNote } from '../../../interfaces/NoteInterface';
import { initialPrescriptionData } from '../../../interfaces/PrescriptionInterface';
import { addToast } from '../../../utils/toastNotifications';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { initialPatient } from '../../../interfaces/PatientInterface';
import { IAppState } from '../../../interfaces/AppInterfaces';

const MedicalAdviceNew = ({
  reportType = 'MedicalAdvice',
}: {
  reportType: 'MedicalAdvice' | 'InvestigationReport';
}) => {
  const history = useHistory();
  const { appState, dispatch }: { appState: IAppState; dispatch: any } =
    useContext(AppContext);

  const [key, setKey] = useState(PrescriptionTabs.patient);
  const [screenStatus, setScreenStatus] = useState({
    basicInfoComplete: false,
    notesComplete: false,
    reportComplete: false,
  });

  const [prescriptionData, setPrescriptionData] = useState(
    initialPrescriptionData,
  );
  const [patientData, setPatientData] = useState(initialPatient);
  const [notesData, setNotesData] = useState(initialPrescriptionNote);

  const handleBasicInfoChange = (input: string) => (event: any) => {
    setPatientData({
      ...patientData,
      [input]: event.target.value,
    });
  };

  const handleChange = (input: string) => (event: any) => {
    setPrescriptionData({
      ...prescriptionData,
      [input]: event.target.value,
    });
  };
  const handleNotesChange = (input: string) => (event: any) => {
    setNotesData({
      ...notesData,
      [input]: event.target.value,
    });
  };

  const handleAddReport = (report: string) => {
    notesData.noteType = reportType;
    prescriptionData.consultationType = reportType;

    prescriptionData.consultationNote = notesData;

    setScreenStatus({
      ...screenStatus,
      reportComplete: true,
    });

    //check if all data filled
    if (!screenStatus.basicInfoComplete) {
      addToast('Please fill the basic information', 'error');
      return;
    }
    if (!screenStatus.notesComplete) {
      addToast('Please fill the notes section', 'error');
      return;
    }
    //save all data to context
    dispatch({
      type: 'SET_PRESCRIPTION_DATA',
      payload: prescriptionData,
    });

    dispatch({
      type: 'SET_PRESCRIPTION_NOTE',
      payload: notesData,
    });

    dispatch({
      type: 'SET_PATIENT_DATA',
      payload: patientData,
    });
    history.push('/prescriptions/medical-advice/summary');
  };

  //Assumption is that the Tab will have enforced form validation before allowing the Continue button
  const navigateToScreen = (tabKey: string, statusKey: string) => {
    setScreenStatus({
      ...screenStatus,
      [statusKey]: true,
    });

    setKey(tabKey);
  };

  const loadStateFromContext = useCallback(() => {
    //check if state exist in context
    if (appState.patientData && appState.patientData !== null) {
      setPatientData(appState.patientData);
    }
    if (appState.prescriptionData && appState.prescriptionData !== null) {
      setPrescriptionData(appState.prescriptionData);
    }
    if (appState.prescriptionNote && appState.prescriptionNote !== null) {
      setNotesData(appState.prescriptionNote);
    }
  }, [
    appState.patientData,
    appState.prescriptionNote,
    appState.prescriptionData,
  ]);

  useEffect(() => {
    loadStateFromContext();
  }, [loadStateFromContext]);

  return (
    <section className='row justify-content-center prescription-summary me-1 ms-1 me-sm-3 ms-sm-3'>
      <fieldset className='col-sm-10 col-md-8 col-lg-5  mt-5'>
        <h5 className='text-center fs-18'>
          {patientData.firstName
            ? `Consultation for : ${patientData.firstName} ${patientData.lastName}`
            : ''}
        </h5>
        <Tabs
          fill
          id='controlled-tab-example'
          activeKey={key}
          onSelect={(k) => setKey(k as string)}
          className='prescription-tab'
        >
          <Tab
            eventKey={PrescriptionTabs.patient}
            title={
              <div className=''>
                <img
                  className='img-fluid mt--1'
                  src={require('../../../assets/images/user.svg').default}
                  alt='user'
                />
                <span className='ms-2'>{PrescriptionTabs.patient}</span>
              </div>
            }
          >
            <BasicInfo
              patientData={patientData}
              handleBasicInfoChange={handleBasicInfoChange}
              serviceCharge={prescriptionData.serviceCharge}
              handleChange={handleChange}
              handleNext={() =>
                navigateToScreen(PrescriptionTabs.notes, 'basicInfoComplete')
              }
            />
          </Tab>
          <Tab
            eventKey={PrescriptionTabs.notes}
            title={
              <div className=''>
                <img
                  className='img-fluid mt--1'
                  src={
                    require('../../../assets/images/clipboard-transparent.svg')
                      .default
                  }
                  alt='clipboard'
                />
                <span className='ms-2'>{PrescriptionTabs.notes}</span>
              </div>
            }
          >
            <MedicalInfoNote
              notesData={notesData}
              handleChange={handleNotesChange}
              handleNext={() =>
                navigateToScreen(
                  reportType === 'MedicalAdvice'
                    ? PrescriptionTabs.medicalAdvice
                    : PrescriptionTabs.investigationReport,
                  'notesComplete',
                )
              }
            />
          </Tab>
          {reportType === 'MedicalAdvice' ? (
            <Tab
              eventKey={PrescriptionTabs.medicalAdvice}
              title={
                <div className=''>
                  <img
                    className='img-fluid mt--1'
                    src={
                      require('../../../assets/images/clipboard-transparent.svg')
                        .default
                    }
                    alt='clipboard'
                  />
                  <span className='ms-2'>{PrescriptionTabs.medicalAdvice}</span>
                </div>
              }
            >
              <MedicalReportEditor
                notesData={notesData}
                handleChange={handleNotesChange}
                handleNext={handleAddReport}
              />
            </Tab>
          ) : (
            <Tab
              eventKey={PrescriptionTabs.investigationReport}
              title={
                <div className=''>
                  <img
                    className='img-fluid mt--1'
                    src={
                      require('../../../assets/images/note-transparent.svg')
                        .default
                    }
                    alt='note'
                  />
                  <span className='ms-2'>
                    {PrescriptionTabs.investigationReport}
                  </span>
                </div>
              }
            >
              <MedicalReportEditor
                notesData={notesData}
                handleChange={handleNotesChange}
                handleNext={handleAddReport}
              />
            </Tab>
          )}
        </Tabs>
      </fieldset>
    </section>
  );
};

export default MedicalAdviceNew;
