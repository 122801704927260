export const formatDate = (dateInput?: string) => {
  if (dateInput === null || typeof dateInput === 'undefined') {
    return '';
  } else {
    return new Date(dateInput).toLocaleDateString();
  }
};

export const formatDateTime = (dateTimeInput?: string) => {
  if (dateTimeInput === null || typeof dateTimeInput === 'undefined') {
    return '';
  } else {
    return new Date(dateTimeInput).toLocaleString('en-GB', { hour12: true }); //toLocaleString([], { hour12: true });
  }
};

export const formatLongDateTime = (dateTimeInput?: string) => {
  if (dateTimeInput === null || typeof dateTimeInput === 'undefined') {
    return '';
  } else {
    return new Date(dateTimeInput).toLocaleString('en-GB', {
      hour12: true,
      month: 'short',
      year: 'numeric',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }); //toLocaleString([], { hour12: true });
  }
};

export const formatTime = (dateTimeInput?: string) => {
  if (dateTimeInput === null || typeof dateTimeInput === 'undefined') {
    return '';
  } else {
    return new Date(dateTimeInput).toLocaleTimeString('en-GB', {
      hour12: true,
    }); //toLocaleString([], { hour12: true });
  }
};

export const timeOfDayGreeting = (username: string) => {
  const hours = new Date().getHours();
  if (hours < 12) {
    //🌞
    return `Good morning  ${username}`;
  } else if (hours < 16) {
    // ☀️;
    return `Good afternoon  ${username}`;
  } else {
    //🌙
    return ` Good evening  ${username}`;
  }
};

export const formatDisplayDate = (dateString?: string) => {
  let date = new Date().toLocaleTimeString('en-gb', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  if (dateString !== undefined) {
    date = new Date(dateString.split('T')[0]).toLocaleDateString('en-gb', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
  return date;
};
