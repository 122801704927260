export interface IPatientData {
  patientId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  location: string;
  gender: string;
  dateOfBirth?: string;
}

export interface IPatientSearchBar {
  callback?: (patient: IPatientData) => void;
  inputStyle?: string;
}

export const initialPatient: IPatientData = {
  patientId: 0,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  location: '',
  gender: 'Others',
};
