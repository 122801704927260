import Header from './Header';
import Sidebar from './Sidebar';
import { Redirect, Route } from 'react-router-dom';
import { useState } from 'react';
import { IPrivateRouteProps } from '../../../interfaces/AppInterfaces';
// import { AppContext } from '../../context/AppContext';
// import * as signalR from '@microsoft/signalr';
import routes from '../../../utils/routes';
import Breadcrumbs from '../common/Breadcrumbs';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';
import ConfirmationModal from '../common/ConfirmationalModal';
// import { endpoints } from '../../utils/URLs';
// import { addToast } from '../../utils/toastNotifications';
import PageLoadingProgressBar from '../common/PageLoadingProgressBar';

const Layout = ({ component: Component, ...rest }: IPrivateRouteProps) => {
  const auth = useAuth();
  const [showModal, setShowModal] = useState(false);
  // const [notificationCount, setNotificationCount] = useState(0);
  // const notificationSound = new Audio('./done-for-you.ogg');
  // const [pushNotificationEnabled, setPushNotificationEnabled] = useState(false);
  // let counter = 1;

  const loginAuth = () => {
    //save the current route so we can navigate to it after login
    sessionStorage.setItem('return-url', window.location.href);
    auth
      .signinRedirect()
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        window.location.href = '/';
      });
  };

  //if an error occurs during renewal,
  if (auth.error) {
    if (showModal) {
      console.log('error');
    } else {
      setShowModal(true);
    }
  }

  if (!auth.isLoading) {
    //if authenticated, set axios header
    if (auth.isAuthenticated) {
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${auth.user?.access_token}`;
    } else {
      loginAuth();
    }
  }

  // //web notification
  // const showNotification = (message: string) => {
  //   const notificationOptions = {
  //     body: message,
  //     icon: '/logo2.png',
  //     vibrate: [100, 50, 100],
  //     data: {
  //       dateOfArrival: Date.now(),
  //       primaryKey: 1,
  //     },
  //     actions: [
  //       {
  //         action: 'close',
  //         title: 'Close the notification',
  //       },
  //     ],
  //   };

  //   if (Notification.permission === 'granted') {
  //     navigator.serviceWorker.getRegistration().then((reg: any) => {
  //       reg.showNotification(
  //         'You have a new notification',
  //         notificationOptions,
  //       );
  //     });
  //   } else {
  //     //get permission
  //     toggleNotification();
  //   }
  // };

  // const checkNotificationPermission = async () => {
  //   if ('Notification' in window && navigator.serviceWorker) {
  //     if (Notification.permission === 'granted') {
  //       //do nothing
  //       // hide bell icon
  //       setPushNotificationEnabled(true);
  //     } else {
  //       setPushNotificationEnabled(false);
  //     }
  //   }
  // };

  // const toggleNotification = async () => {
  //   //if notification enabled, do nothing
  //   //else enable
  //   if ('Notification' in window && navigator.serviceWorker) {
  //     if (Notification.permission === 'granted') {
  //       // hide bell icon
  //       setPushNotificationEnabled(true);
  //     } else {
  //       //show bell icon
  //       //request for notification
  //       try {
  //         await Notification.requestPermission((status) => {
  //           console.log(status);
  //           if (status === 'granted') {
  //             navigator.serviceWorker.getRegistration().then((reg: any) => {
  //               reg.showNotification('You have enabled notifications');
  //             });
  //             //hide bell
  //             setPushNotificationEnabled(true);
  //           } else {
  //             console.log('notification disabled');
  //           }
  //         });
  //       } catch (error) {
  //         console.error('Permission error occured');
  //       }
  //     }
  //   }
  // };

  // const updateNotificationCount = (message: any) => {
  //   console.log('notification object', message);
  //   counter = counter + 1;
  //   setNotificationCount(counter);
  //   // //play sound
  //   notificationSound.play();
  //   showNotification(message);

  //   //add to context
  // };

  // const connection = new signalR.HubConnectionBuilder()
  //   .withUrl(endpoints.website.realTime, {
  //     skipNegotiation: true,
  //     transport: signalR.HttpTransportType.WebSockets,
  //     // accessTokenFactory: () => {
  //     //   return savedToken;
  //     // },
  //   })
  //   .withAutomaticReconnect()
  //   .configureLogging(signalR.LogLevel.Information)
  //   .build();

  // const start = async () => {
  //   try {
  //     await connection.start();
  //     console.assert(connection.state === signalR.HubConnectionState.Connected);
  //     console.log('connected');
  //   } catch (err) {
  //     console.assert(
  //       connection.state === signalR.HubConnectionState.Disconnected,
  //     );
  //     console.error(err);
  //     setTimeout(() => start(), 5000);
  //   }
  // };

  // connection.onclose(async () => {
  //   await start();
  // });

  // connection.onreconnecting((error) => {
  //   console.assert(
  //     connection.state === signalR.HubConnectionState.Reconnecting,
  //   );
  //   addToast('Connection lost. Trying to reconnect', 'warning', true);
  // });

  // connection.onreconnected((connectionId) => {
  //   console.assert(connection.state === signalR.HubConnectionState.Connected);
  //   addToast('Connection re-established', 'success', true);
  // });

  // connection.on('ReceiveErxNotification', updateNotificationCount);

  // useEffect(() => {
  //   checkNotificationPermission();
  // }, []);

  // useEffect(() => {
  //   //if (process.env.NODE_ENV !== 'development') {
  //   start();
  //   //}
  //   //disabled lint because there is no other viable alternative
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div>
      {showModal ? (
        <ConfirmationModal
          show={showModal}
          hide={() => setShowModal(false)}
          title='Session expired'
          okCallback={auth.signinRedirect}
          content='Click Ok to Login'
        />
      ) : (
        <div></div>
      )}
      <Header
        notificationCount={0} //{notificationCount}
        notificationEnabled={false} //{pushNotificationEnabled}
        toggleNotification={undefined} //{toggleNotification}
      />
      <PageLoadingProgressBar />

      <div className='container-fluid register-section sidebar-body'>
        <span
          id='request-spinner'
          className='spinner-border spinner-border-sm text-success request-spinner d-none'
          role='status'
          aria-hidden='true'
        ></span>

        <div className='row'>
          <Sidebar />
          <main
            role='main'
            className='col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-5'
          >
            <Route
              {...rest}
              render={(props: any) => {
                const crumbs = routes
                  // Get all routes that contain the current one.
                  .filter(({ path }) => props.match.path.includes(path))
                  // Swap out any dynamic routes with their param values.
                  // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                          (path, param) =>
                            path.replace(
                              `:${param}`,
                              props.match.params[param],
                            ),
                          path,
                        )
                      : path,
                    ...rest,
                  }));

                return auth.isLoading ? (
                  <div>Loading...</div>
                ) : auth.isAuthenticated ? (
                  <div>
                    <Breadcrumbs crumbs={crumbs} />
                    <Component {...props} />
                  </div>
                ) : (
                  <Redirect to='/' />
                );
              }}
              // render={(props) =>
              //   isAuthenticated ? <Component {...props} /> : <Redirect to='/' />
              // }
            />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
