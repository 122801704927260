const NameBadge = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  return (
    <div className='name-badge'>
      {firstName && firstName.length > 0 ? firstName[0] : '-'}{' '}
      {lastName && lastName.length > 0 ? lastName[0] : '-'}
    </div>
  );
};

export default NameBadge;
