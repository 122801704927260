const RefreshToast = () => {
  //a hack to unregister the current service worker and force a reload
  //https://stackoverflow.com/a/46982400/2929906
  const reloadPage = (event: any) => {
    if ('serviceWorker' in window.navigator) {
      window.navigator.serviceWorker
        .getRegistration()
        .then((registration) => {
          if (registration) {
            registration?.unregister().then(() => {
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error('No service worker in navigator');
    }
  };

  return (
    <div
      id='refresh-btn'
      className='refresh-toast'
      onClick={reloadPage}
      style={{ display: 'none' }}
    >
      New version available!! Click to update
      {/* <button
        className='btn btn-primary btn-sm'
        style={{ marginLeft: '10px' }}
      ></button> */}
    </div>
  );
};

export default RefreshToast;
