import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import {
  IWalletAccountSummary,
  IWalletTransactionHistory,
} from '../../../interfaces/WalletInterface';

import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URLs';
import Loader from '../../components/common/Loader';
import { formatDate, formatTime } from '../../../utils/dateTimeUtils';
import WalletModal from './WalletModal';

const WalletDashboard = ({ history }: any) => {
  const { appState, dispatch } = useContext(AppContext);

  const [walletFound, setWalletFound] = useState(false);
  const [statePractitionerId, setStatePractitionerId] = useState(0);

  const [isLoading, setLoading] = useState(true);
  const [showLoadButton, setShowLoadButton] = useState(false);

  const [pageIndex, setPageIndex] = useState(1);

  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [transactionHistory, setTransactionHistory] = useState<
    IWalletTransactionHistory[]
  >([]);
  const [walletSummary, setWalletSummary] = useState<IWalletAccountSummary>({
    walletBalance: 0.0,
    totalCredits: 0.0,
    totalDebits: 0.0,
    bankAccountName: '',
    bankAccountNumber: '',
    bankName: '',
  });

  const getPractitionerId = useCallback(() => {
    //const applicationState = state as IAppState;
    const practitionerId = appState.practitioner?.practitionerId;

    if (typeof practitionerId === 'undefined' || practitionerId === 0) {
      setLoading(false);
      setWalletFound(false);
      return 0;
    }
    setStatePractitionerId(practitionerId);
  }, [appState.practitioner?.practitionerId]);

  const getTransactionHistory = useCallback(async (pageNumber: number) => {
    //call API
    const url = `${
      endpoints.practitionerWallet.mainUrl
    }/transactions?pageNumber=${pageNumber}&pageSize=${10}`;

    try {
      const response = await axios.get(url);

      if (response.data.length > 0) {
        setShowLoadButton(true);

        setTransactionHistory((old) => [...old, ...response.data]);
      } else {
        setShowLoadButton(false);
      }
    } catch (error) {
      addToast('An error occured', 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getWalletSummary = useCallback(async () => {
    setLoading(true);
    //call API
    const url = `${endpoints.practitionerWallet.mainUrl}`;

    try {
      const response = await axios.get(url);
      setWalletSummary(response.data);

      // //save to context
      dispatch({
        type: 'SET_WALLET',
        payload: response.data,
      });

      setWalletFound(true);
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          setWalletFound(false);
        } else {
          addToast('An error occured', 'error');
        }
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const loadMoreData = (event: any) => {
    event.preventDefault();
    const updatedPageIndex = pageIndex + 1;
    setPageIndex(updatedPageIndex);

    getTransactionHistory(updatedPageIndex);
  };

  const toggleModal = () => {
    setIsEdit(false);
    setShow(!show);
  };

  const toggleAccountAction = () => {
    if (walletSummary.bankAccountNumber === '') {
      // means account not set
      //open modal to update account details
      setIsEdit(true);
      setShow(!show);
    } else {
      //show withdrawal
      history.push('/wallets/withdrawal');
    }
  };

  useEffect(() => {
    getPractitionerId();
  }, [getPractitionerId]);

  useEffect(() => {
    getWalletSummary();
  }, [getWalletSummary]);

  useEffect(() => {
    getTransactionHistory(1);
  }, [getTransactionHistory]);

  const noWallet =
    statePractitionerId > 0 ? (
      <div className='text-center mt-3'>
        <button className='btn btn-secondary' onClick={toggleModal}>
          Click here to create your wallet
        </button>
      </div>
    ) : (
      <div className='text-center'>
        No wallet found. Please complete your sign up or contact admin
      </div>
    );

  const formatTransactionType = (transactionType: string) => {
    if (transactionType === 'Credit') {
      return 'col-3 text-success';
    }
    return 'col-3 text-danger';
  };

  const formatTransactionStatus = (transactionStatus: string) => {
    switch (transactionStatus) {
      case 'Completed':
        return 'col-3 text-success';
      case 'Hold':
        return 'col-3 text-warning';
      case 'Cancelled':
        return 'col-3 text-danger';
      default:
        return 'col-3 text-info';
    }
  };

  const walletDisplay = (
    <div>
      <div style={{ marginTop: '20px' }}>
        {/* <Link
          to='/wallets/withdrawal'
          className='btn btn-default btn-primary'
          style={{ backgroundColor: '#191489' }}
        >
          Withdraw
        </Link> */}
        <button
          onClick={toggleAccountAction}
          className='btn btn-default btn-primary'
          style={{ backgroundColor: '#191489' }}
        >
          Withdraw
        </button>
      </div>
      <div className='row'>
        <div className='col-sm-4'>
          <div className='card prescription-card'>
            <div className='card-body'>
              <h6 className='card-title' style={{ fontWeight: 600 }}>
                Balance
              </h6>
              <div className='card-text prescription-card-text'>
                <div
                  className='text-center text-info'
                  style={{ fontSize: '2.5rem' }}
                >
                  ₦ {walletSummary.walletBalance.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-sm-4'>
          <div className='card prescription-card'>
            <div className='card-body'>
              <h6 className='card-title' style={{ fontWeight: 600 }}>
                Inflow
              </h6>
              <div className='card-text prescription-card-text'>
                <div
                  className='text-center text-success'
                  style={{ fontSize: '2.5rem' }}
                >
                  ₦ {walletSummary.totalCredits.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-sm-4'>
          <div className='card prescription-card'>
            <div className='card-body'>
              <h6 className='card-title' style={{ fontWeight: 600 }}>
                Outflow
              </h6>
              <div className='card-text prescription-card-text'>
                <div
                  className='text-center text-danger'
                  style={{ fontSize: '2.5rem' }}
                >
                  ₦ {walletSummary.totalDebits.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <h6 className='fs-20'>Transaction History</h6>
      <div className='mt-4'>
        <div className='row text-left font-weight-bold'>
          <div className='col-3'>Date</div>
          <div className='col-3'>Amount</div>
          <div className='col-3'>Transaction type</div>
          <div className='col-3'>Status</div>
        </div>
        {transactionHistory.map((groupData, key) => (
          <div
            key={key}
            className='row'
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <div className='col-12'>
              <div>{formatDate(groupData.transactionDate)}</div>
              {groupData.transactions.map((data, key2) => (
                <div key={key2} className='row text-left transaction'>
                  <div className='col-3'>
                    {formatTime(data.transactionDate)}
                  </div>
                  <div className={formatTransactionType(data.transactionType)}>
                    {data.amount}
                  </div>
                  <div className={formatTransactionType(data.transactionType)}>
                    {data.transactionName}
                  </div>
                  <div
                    className={formatTransactionStatus(data.transactionStatus)}
                  >
                    {data.transactionStatus}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className='text-center'>
        {showLoadButton ? (
          <button className='btn btn-primary' onClick={loadMoreData}>
            Load more
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  let walletModal = (
    <WalletModal
      getWalletSummary={getWalletSummary}
      show={show}
      close={toggleModal}
      isEdit={isEdit}
      practitionerId={statePractitionerId}
    />
  );

  return (
    <div>
      {walletModal}
      <h5
        className='text-center'
        style={{ marginTop: '20px', fontWeight: 500, color: '#191489' }}
      >
        Wallets Dashboard
      </h5>
      {isLoading ? <Loader /> : walletFound ? walletDisplay : noWallet}
    </div>
  );
};

export default WalletDashboard;
