import axios, { InternalAxiosRequestConfig } from 'axios';
import { addToast } from './toastNotifications';

export const toggleSpinner = (state: string) => {
  const spinner = document.getElementById('request-spinner');

  if (spinner) {
    //if (spinner.classList.toString().includes("show")) {
    if (state.includes('show')) {
      spinner.classList.remove('d-none');
      spinner.classList.add('d-block');
    } else {
      spinner.classList.remove('d-block');
      spinner.classList.add('d-none');
    }
  }
};
// //add token to all request
axios.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    if (config.method === 'get') {
      toggleSpinner('show');
    }
    // get token
    // const token = '';
    // if (token !== null) {
    //   config.headers.common['Authorization'] = `Bearer ${token}`;
    // }
    return config;
  },
  function (error) {
    toggleSpinner('hide');
    Promise.reject(error);
  },
);

const setAuthToken = (token?: string): void => {
  if (token) {
    // apply to every request
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // delete auth header
    delete axios.defaults.headers.common.Authorization;
  }
};

// Add a 401 response0
axios.interceptors.response.use(
  (response) => {
    toggleSpinner('hide');
    // toggleLoaderScreen('hide');
    return response;
  },
  (error) => {
    toggleSpinner('hide');
    //toggleLoaderScreen('hide');
    // if error woth request, e.g network error
    // if (error.request) {
    //   return; // Promise.reject();
    // }

    if ((error.response && error.response.status) === 500) {
      //Sentry.captureException(error);
      addToast(
        'There is a server error, Please contact admin. Code 500.',
        'error',
      );
    }

    if ((error.response && error.response.status) === 422) {
      addToast(`Uprocessable. ${error.response.data.detail}`, 'error');
    }

    if ((error.response && error.response.status) === 400) {
      //if the object 'error' occurs, then likely dealing with array of error. we show the first one
      if (error.response.data.errors) {
        const responseErrors = error.response.data.errors;
        const allErrors = Object.values(responseErrors)[0];

        if (Array.isArray(allErrors)) {
          addToast(`Bad request. ${allErrors[0]}`, 'error');
        } else {
          addToast(`Bad request. ${responseErrors}`, 'error');
        }
      }

      if (error.response.data.detail) {
        addToast(`Bad request. ${error.response.data.detail}`, 'error');
      }
      // Sentry.captureException(error);
    }

    if ((error.response && error.response.status) === 404) {
      addToast(`Not found. ${error.response.data.detail}`, 'error');
    }

    if ((error.response && error.response.status) === 409) {
      addToast(`${error.response.data.detail}`, 'error');
    }

    if ((error.response && error.response.status) === 403) {
      addToast('You do not have permission to perform this action', 'error');
    }

    return Promise.reject(error);
  },
);

export default setAuthToken;
