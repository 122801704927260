import { IPractitionerData } from '../../../interfaces/PractitionerInterface';

const PrescriptionSummaryFooter = ({
  practitionerData,
}: {
  practitionerData?: IPractitionerData;
}) => {
  return (
    <div className='mt-5'>
      <div className='fw-600'>
        Dr {practitionerData?.firstName} {practitionerData?.lastName}
      </div>
      {/* <div className='mt-0'>
        <img
          src={require('../../../assets/images/signature.svg').default}
          alt='Logo'
          width='64'
          height='50'
        />
      </div> */}
      <div>
        Phone number: <span>{practitionerData?.phoneNumber}</span>
      </div>
      <div className='mb-2'>
        Email: <span>{practitionerData?.email}</span>
      </div>
    </div>
  );
};

export default PrescriptionSummaryFooter;
