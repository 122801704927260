import { useContext } from 'react';
import { endpoints } from '../../../utils/URLs';
import { AppContext } from '../../../context/AppContext';
import {
  IPractitionerData,
  practitionerData,
} from '../../../interfaces/PractitionerInterface';
import useSWR from 'swr';
import fetcher from '../../../utils/axiosFetcher';
import DoctorProfile from '../../components/dashboard/DoctorProfile';
import ScreenLoading from '../../components/common/ScreenLoading';
import QuickTiles from '../../components/dashboard/QuickTiles';
import AnalyticsOverview from '../../components/dashboard/Analytics/AnalyticsOverview';

const Dashboard = () => {
  const { dispatch } = useContext(AppContext);

  const saveToContext = (data: IPractitionerData, key: string, config: any) => {
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: data,
    });
  };

  const { data, isLoading } = useSWR<IPractitionerData, any>(
    endpoints.practitioner.mainUrl,
    fetcher,
    {
      fallbackData: practitionerData,
      errorRetryCount: 1,
      revalidateOnFocus: false,
      onSuccess: saveToContext,
    },
  );

  return (
    <div className='row justify-content-center'>
      {isLoading ? (
        <ScreenLoading />
      ) : (
        <div className='col-md-12'>
          <DoctorProfile {...data!} />
          <br />
          <QuickTiles {...data!} />
          <AnalyticsOverview />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
