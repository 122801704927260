import { useContext, useEffect } from 'react';
import { IAppState } from '../../../interfaces/AppInterfaces';
import { AppContext } from '../../../context/AppContext';
import PrescriptionNew from './PrescriptionNew';

const NewTelePrescription = () => {
  const { appState }: { appState: IAppState } = useContext(AppContext);

  useEffect(() => {
    if (
      appState.practitioner!.practitionerId === 0 ||
      appState.practitioner!.source !== 2
    ) {
      window.location.href = '/dashboard';
    }
  }, [appState.practitioner]);

  return <PrescriptionNew source='Telemedicine' />;
};

export default NewTelePrescription;
