import { Link } from 'react-router-dom';
import RecentTransaction from './RecentTransactions';
import { useWalletReport } from '../../../../hooks/WalletReportHook';
import { NairaSymbol } from '../../../../utils/mappings';

const WalletTransactionsOverview = () => {
  const { walletSummary } = useWalletReport();

  return (
    <div className='analytics-card1'>
      <div className='analytics-card1-inner'>
        <div>
          <div className='fs-20 fw-600 analytics-card1-header grey-600'>
            Recent Transactions
          </div>
          <div className='mt-2 fw-600 fs-32 grey-700 analytics-card1-header'>
            {NairaSymbol}
            {walletSummary?.walletBalance}
          </div>
          <div className='mt-1 d-flex'>
            <img
              src={
                require(`../../../../assets/images/icon-chart-up.svg`).default
              }
              className='img-fluid'
              alt='chart'
            />
            <div className='fw-500 grey-600 fs-12 text-spacer1'>
              <span className='ms-2'>Account Balance</span>
            </div>
          </div>
        </div>
        <img
          src={require(`../../../../assets/images/wallet-2.svg`).default}
          className='img-fluid'
          alt='wallet'
        />
      </div>
      <div className='d-flex mt-2'>
        <span className='debits-badge ms-2 fs-12 fw-500 text-spacer1'>
          {walletSummary?.weeklyDebit}
        </span>
        <span className='credits-badge ms-2 fs-12 fw-500 text-spacer1'>
          {walletSummary?.weeklyCredit}
        </span>
        <span className='ms-2 fs-12 fw-500 text-spacer1 m-auto'>Week</span>
      </div>
      <div className='mt-4'>
        {walletSummary?.topTwoTransactions?.map((transaction, key) => (
          <RecentTransaction {...transaction} key={key} />
        ))}
      </div>
      <Link
        to='/wallets'
        className='mt-4 analytics-card1-inner border-top pt-3'
      >
        <div className='fs-12 fw-500 primary-7'>View All Transactions</div>
        <img
          src={require(`../../../../assets/images/arrow-right.svg`).default}
          alt='arrow-right'
          className='img-fluid'
        />
      </Link>
    </div>
  );
};

export default WalletTransactionsOverview;
