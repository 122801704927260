import { FormEvent } from 'react';
import { IPrescriptionSummaryAction } from '../../../interfaces/PrescriptionInterface';

const PrescriptionSummaryAction = ({
  sendPrescription,
  editPrescription,
  handleChange,
  options,
}: IPrescriptionSummaryAction) => {
  const handleCheckChange = (input: string) => (event: any) => {
    const value = event.target.checked;
    if (handleChange) {
      handleChange(input, value);
    }
  };

  const edit = (event: FormEvent) => {
    if (editPrescription) {
      editPrescription();
    }
  };
  const submit = (event: FormEvent) => {
    event.preventDefault();
    if (sendPrescription) {
      sendPrescription();
    }
  };

  return (
    <div className='col-lg-6 align-grid-items-bottom mb-4'>
      <div className=''>
        <div className='fs-20 fw-400'>
          Here is a preview of your prescription
        </div>
        <div className='mt-2'>
          How would you like us to send this pay-walled document to your
          patient?
        </div>
        <div className='p-4'>
          <div className='d-flex gap-2 mt-3'>
            <input
              className='primary-7 form-check fs-12 prescription-check'
              type='checkbox'
              checked={options.sendViaWhatsapp}
              onChange={handleCheckChange('sendViaWhatsapp')}
              id='sendViaWhatsapp'
            />
            <span className='primary-7 bi bi-whatsapp'></span>
            <label htmlFor='sendViaWhatsapp' className='grey-700 fw-500 fs-16'>
              Send via Whatsapp
            </label>
          </div>
          <div className='d-flex gap-2 mt-3'>
            <input
              className='primary-7 form-check fs-12 prescription-check'
              type='checkbox'
              checked={options.sendViaSms}
              onChange={handleCheckChange('sendViaSms')}
              id='sendViaSms'
            />
            <span className='primary-7 bi bi-chat-square-text '></span>
            <label htmlFor='sendViaSms' className='grey-700 fw-500 fs-16'>
              Send via SMS
            </label>
          </div>
        </div>
      </div>
      <div className='row '>
        <div className='col-6 d-grid'>
          <button
            type='submit'
            className='btn outline-primary-button'
            onClick={edit}
          >
            Edit
          </button>
        </div>
        <div className='col-6 d-grid'>
          <button type='submit' className='btn btn-primary' onClick={submit}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionSummaryAction;
