import { Link } from 'react-router-dom';
import ConsultationMetric from './ConsultationMetric';
import { IConsultationSummary } from '../../../../interfaces/ReportInterface';
import { useConsultationReport } from '../../../../hooks/ConsultationReportHook';

const ConsultationOverview = () => {
  const { consultation } = useConsultationReport();

  const getPercentChange = (data: IConsultationSummary) => {
    const change = data.consultationsThisMonth - data.consultationsLastMonth;

    if (data.consultationsLastMonth === 0) {
      return 0;
    }

    const percentChange = (
      (change / data.consultationsLastMonth) *
      100
    ).toFixed(2);

    return percentChange;
  };

  return (
    <div className='analytics-card1'>
      <div className='analytics-card1-inner'>
        <div>
          <div className='fs-20 fw-600 analytics-card1-header grey-600'>
            Consultations This Month
          </div>
          <div className='mt-2 fw-600 fs-32 grey-700 analytics-card1-header'>
            {consultation?.consultationsThisMonth}
          </div>
          <div className='mt-1 d-flex'>
            <img
              src={
                require(`../../../../assets/images/icon-chart-up.svg`).default
              }
              className='img-fluid'
              alt='chart'
            />
            <div className='fw-500 grey-600 fs-12 text-spacer1'>
              {getPercentChange(consultation!)}%
              <span className='ms-2'>This month</span>
            </div>
          </div>
        </div>
        <img
          src={require(`../../../../assets/images/stethoscope.svg`).default}
          className='img-fluid'
          alt='Consultations'
        />
      </div>
      <div className='mt-4 analytics-card1-inner row'>
        <div className='col-sm-4'>Graph</div>
        <div className='col-sm-8'>
          <div className='fs-12 fw-500 text-start'>
            Here's how all your activities compare:
            <ConsultationMetric
              count={consultation!.consultationMetric.investigationReports}
              text={'Investigation reports'}
              percent={0}
              icon='metric1-bar.svg'
            />
            <ConsultationMetric
              count={consultation!.consultationMetric.prescriptions}
              text={'Prescriptions'}
              percent={
                consultation?.consultationMetric.prescriptions! > 0 ? 100 : 0
              }
              icon='metric2-bar.svg'
            />
            <ConsultationMetric
              count={consultation!.consultationMetric.medicalAdvices}
              text={'Medical Advice'}
              percent={0}
              icon='metric3-bar.svg'
            />
          </div>
        </div>
      </div>
      <Link
        to='/prescriptions'
        className='mt-4 analytics-card1-inner border-top pt-3'
      >
        <div className='fs-12 fw-500 primary-7'>View consultations</div>
        <img
          src={require(`../../../../assets/images/arrow-right.svg`).default}
          alt='arrow-right'
          className='img-fluid'
        />
      </Link>
    </div>
  );
};

export default ConsultationOverview;
