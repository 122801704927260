import { useReducer, createContext, useEffect } from 'react';
import { IAppState } from '../interfaces/AppInterfaces';
import { practitionerData } from '../interfaces/PractitionerInterface';
import { walletData } from '../interfaces/WalletInterface';
import appReducer from './AppReducer';
import setAuthToken from '../utils/setAuthToken';
import { initialRequest } from '../interfaces/TelemedicineRequestsInterface';

const initialState: IAppState = {
  token: '',
  isAuthenticated: true,
  practitioner: practitionerData,
  prescriptionResponse: undefined,
  role: 'User',
  wallet: walletData,
  telemedicineRequest: initialRequest,
};

export const AppContext = createContext<IAppState | any>(initialState);
// export const AppContext = createContext<IAppState | Record<string, unknown>>(
//   initialState,
// );

const loadState = () => {
  try {
    const token = localStorage.getItem('token') as string;
    setAuthToken(token);
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// get state from session storage
const storedState = loadState();

// provider function
//export const AppProvider = (props: ContextProps): JSX.Element => {
export const AppProvider = (props: any): JSX.Element => {
  const [appState, dispatch] = useReducer(
    appReducer,
    storedState || initialState,
  );
  const { children } = props;

  // save state to session everytime the values in state changes.
  useEffect(() => {
    localStorage.setItem('state', JSON.stringify(appState));
  }, [appState]);

  return (
    <AppContext.Provider value={{ appState, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
